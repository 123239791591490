import React from "react";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Heading from "../Heading";
import "./Slide3.css";

function Slide3({
  logo,
  fontColor,
  headingFontWeight,
  headingFontSize,
  subHeadingFontSize,
  subHeadingFontWeight,
  illustrationTopLeft,
  illustrationBottomRight,
  placement,
  labels,
}) {
  const { productSelectedVal, productImageURL, blobProductImageURL } = useSelector((state) => state.createBrief);
  const { aboutProduct } = useSelector((state) => state.editYourBrief);

  const getImageStyles = (index, count) => {
    if (count === 1) {
      return {
        margin : "100px"
      };
    }
  
    if (count === 2) {
      return {
        // margin : "2px",
        width: "35%", // Use 48% for each image to fit side-by-side with a small gap
        // height: "70%", // Fixed height for both images to ensure uniformity
      };
    }
  
    if (count === 3) {
      if (index < 2) {
        return {
          width: "48%", // Same as in the two-image layout
          height: "210px", // Set a fixed height for uniformity in the first row
        };
      }
      return {
        width: "457px",
        height: "305px",
      };
    }
  
  };
  
  
  return (
    <Box display="flex" height="100%" className={`${placement}`}>
      <Box className="slide3Left" height="100%" position="relative">
        <Box>{illustrationTopLeft && <img src={illustrationTopLeft} alt="Top Left Illustration" />}</Box>
        <Box className="contentMain">
          <Heading
            text={`${labels.ALL_ABOUT} ${productSelectedVal && productSelectedVal.length > 0 && productSelectedVal.map(product => product.product_name).join(', ')}`}
            textColor={fontColor}
            textSize={headingFontSize}
            textWeight={headingFontWeight}
          />
          <Heading
            text={aboutProduct}
            textColor={fontColor}
            textSize={subHeadingFontSize}
            textWeight={headingFontWeight}
          />
        </Box>
        <Box className="illustration2Slide3">
          {illustrationBottomRight && <img src={illustrationBottomRight} alt="Bottom Right Illustration" />}
        </Box>
      </Box>
      <Box className="slide3Right">
        <Box display="flex" justifyContent="center" alignItems="center" flexWrap= {productImageURL.length===3 && "wrap"} gap={2}>
          {productImageURL.map((url, index) => (
            <Box
              key={index}
              style={{
                ...getImageStyles(index, productImageURL.length),
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={url || blobProductImageURL} alt="Product is loading" className="productImage" />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

export default Slide3;
