import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Header from "../../components/Header/Header";
import CustomCard from "../../components/CustomCard/CustomCard";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import { fetchData } from "../../utilities/apiUtils/apiUtils";
import ViewPreviousBriefModal from "../../components/ViewPreviousBriefModal/ViewPreviousBriefModal";
import { setActiveIndexofTab } from "../../slicers/editYourBriefSlicer";
import "./Home.css";
import peopleChefImg from "../../assets/images/People chef transparent background 1.png";
import peopleChefBackground from "../../assets/images/peopleChefBackground.png";
import instaIcon from "../../assets/images/instaIcon.png";
import tiktokIcon from "../../assets/images/tiktokIcon.png";
import youtubeIcon from "../../assets/images/youtubeIcon.png";

function Home() {
  const dispatch = useDispatch();
  const {
    isLoading,
    tastewiseSelectionValue,
    marketSelectedVal,
    brandSelectedVal,
    topDishTastewiseSelectedVal,
    topDishSelectedVal,
    productSelectedVal,
    productOthersSelectedVal,
    productDescriptionSelectedVal,
    trendingRecipeSelectedVal,
    dataSourceUserSelectionName,
    trendingRecipeOptionsTastewise,
    isViewPreviousBriefModalOpen
  } = useSelector((state) => state.createBrief);

  useEffect(() => {
    // setting the active of edit brief page to preview tab
    dispatch(setActiveIndexofTab(3));
    if (marketSelectedVal === "") {
      console.log("called if 888",tastewiseSelectionValue)
      fetchData(dispatch, tastewiseSelectionValue);
    } else {
      console.log("called else 888")
      if(!isViewPreviousBriefModalOpen){
        fetchData(
          dispatch,
          tastewiseSelectionValue,
          marketSelectedVal,
          brandSelectedVal,
          tastewiseSelectionValue === "Top Dish Trend Index"
            ? topDishTastewiseSelectedVal
            : topDishSelectedVal,
          productSelectedVal && productSelectedVal.map(product => product.product_name).join(', '),
          productDescriptionSelectedVal || "", 
          trendingRecipeSelectedVal || "",  
          dataSourceUserSelectionName || "", 
          trendingRecipeOptionsTastewise || "",
        );
        }
      
    }
  }, []);

  return (
    <Box position="relative">
      <Box>
        {isLoading && <LoadingScreen />}
        {/* Render the loading screen if isLoading is true */}
        <ViewPreviousBriefModal />
        <Grid container spacing={0.5} justifyContent="center">
          <Grid item xs={12}>
            <Header />
          </Grid>
          <Grid item xs={12}>
            <CustomCard />
          </Grid>
        </Grid>
      </Box>
      <Box position="absolute" bottom={66} zIndex={-1} width="100%">
        <img
          src={peopleChefBackground}
          alt="People Chef Background"
          className="briefStepBackgroundCreateBrief"
        />
        <img
          src={peopleChefImg}
          alt="People Chef"
          className="chefImgCreateBrief"
        />
        <Box>
          <img
            src={instaIcon}
            className="instaIconCreateBriefHome"
            width={77.7}
            height={78}
          />
          <img
            src={youtubeIcon}
            className="youTubeIconCreateBriefHome"
            width={105}
            height={66}
          />
          <img
            src={tiktokIcon}
            className="tiktokIconCreateBriefHome"
            width={66}
            height={71}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default Home;
