import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    topDishTastewiseSelectedVal: "",
    trendingRecipeTastewiseOthersSelectedVal: "",
    trendingRecipeTastewiseSelectedVal: "",
    trendingRecipeTastewiseOptions: [],
    trendingRecipeTastewiseIndexScoreSelectedVal: 0.0,
    topDishSelectedVal: "",
    topDishOptions: [],
    trendingRecipeOthersSelectedVal: "",
    trendingRecipeSelectedVal: "Others",
    trendingRecipeOptions: [],
    productOthersSelectedStatus: false,
    productOthersSelectedVal: [],
    productSelectedVal: [],
    productOptions: [],
    productDescriptionOthersSelectedVal: [],
    productDescriptionSelectedVal: "",
    userPromptSelectedVal: "",
    marketSelectedVal: "",
    marketOptions: [],
    brandSelectedVal: "",
    brandOptions: [],
    tastewiseSelectionValue: "Type It In",
    isBlobImageLoading : false,
    uploadedImageURL: '',
    uploadedFileName: '',
    brandImageFileName: '',
    productImageFileName: [],
    campaignImageFileName: '',
    inspirationImageFileName: [],
    brandImageURL: '',
    productImageURL: [],
    campaignImageURL: '',
    inspirationImageURL: [],
    briefId: '',
    briefData:{},
    isLoading: false,
    isViewPreviousBriefModalOpen: false,
    userName: '',
    userEmail: '',
    blobProductImageURL:[],
    isTastewiseFeatureRequired:true,
    multipleSelectedProducts:{},
    isAlertMessageOpen:false,
    alertMessageText:""
  };
  

export const createBriefSlicer = createSlice({
  name: "createBrief",
  initialState,
  reducers: {
    // Tastewise section, Top Dish Trend Index
    setTastewiseSelectionValue: (state, action) => ({
      ...state,
      tastewiseSelectionValue: action.payload,
    }),
    setIsTastewiseFeatureRequired: (state, action) => ({
      ...state,
      isTastewiseFeatureRequired: action.payload,
    }),
    setBlobProductImageURL: (state, action) => ({
      ...state,
      blobProductImageURL: [...state.blobProductImageURL, action.payload],
    }),
    setProductImageURL: (state, action) => ({
      ...state,
      productImageURL: [...state.productImageURL, action.payload],
    }),
    setTopDishTastewiseSelectedVal: (state, action) => ({
      ...state,
      topDishTastewiseSelectedVal: action.payload,
    }),
    setTrendingRecipeTastewiseOthersSelectedVal: (state, action) => ({
      ...state,
      trendingRecipeTastewiseOthersSelectedVal: action.payload,
    }),
    setTrendingRecipeTastewiseSelectedVal: (state, action) => ({
      ...state,
      trendingRecipeTastewiseSelectedVal: action.payload,
    }),
    setTrendingRecipeTastewiseOptions: (state, action) => ({
      ...state,
      trendingRecipeTastewiseOptions: action.payload,
    }),
    setTrendingRecipeTastewiseIndexScoreSelectedVal: (state, action) => ({
      ...state,
      trendingRecipeTastewiseIndexScoreSelectedVal: action.payload,
    }),
    // Non-tastewise section, Type it in section
    setTopDishSelectedVal: (state, action) => ({
      ...state,
      topDishSelectedVal: action.payload,
    }),
    setTopDishOptions: (state, action) => ({
      ...state,
      topDishOptions: action.payload,
    }),
    setTrendingRecipeOthersSelectedVal: (state, action) => ({
      ...state,
      trendingRecipeOthersSelectedVal: action.payload,
    }),
    setTrendingRecipeSelectedVal: (state, action) => ({
      ...state,
      trendingRecipeSelectedVal: action.payload,
    }),
    setTrendingRecipeOptions: (state, action) => ({
      ...state,
      trendingRecipeOptions: action.payload,
    }),
    setProductOthersSelectedStatus: (state, action) => ({
      ...state,
      productOthersSelectedStatus: action.payload,
    }),
    setProductOthersSelectedVal: (state, action) => ({
      ...state,
      productOthersSelectedVal: action.payload,
    }),
    setProductSelectedVal: (state, action) => ({
      ...state,
      productSelectedVal: action.payload,
    }),
    setProductOptions: (state, action) => ({
      ...state,
      productOptions: action.payload,
    }),
    setProductDescriptionOthersSelectedVal: (state, action) => ({
      ...state,
      productDescriptionOthersSelectedVal: action.payload,
    }),
    setProductDescriptionSelectedVal: (state, action) => ({
      ...state,
      productDescriptionSelectedVal: action.payload,
    }),
    setUserPromptSelectedVal: (state, action) => ({
      ...state,
      userPromptSelectedVal: action.payload,
    }),
    setMarketSelectedVal: (state, action) => ({
      ...state,
      marketSelectedVal: action.payload,
    }),
    setMarketOptions: (state, action) => ({
      ...state,
      marketOptions: action.payload,
    }),
    setBrandSelectedVal: (state, action) => ({
      ...state,
      brandSelectedVal: action.payload,
    }),
    setBrandOptions: (state, action) => ({
      ...state,
      brandOptions: action.payload,
    }),
    setIsBlobImageLoading : (state, action) =>({
      ...state,
      isBlobImageLoading : action.payload,
    }),
    setUploadedImage: (state, action) => ({
      ...state,
      uploadedImageURL: action.payload,
    }),
    setUploadedImageURL: (state, action) => {
      const { tab, url } = action.payload;
      switch (tab) {
        case "Brand":
          return {
            ...state,
            brandImageURL: url,
          };
          case "Product":
            return {
              ...state,
              productImageURL: url ? [...state.productImageURL, url] : state.productImageURL,
            };
          
        case "Campaign":
          return {
            ...state,
            campaignImageURL: url,
          };
        case "Inspiration":
          return {
            ...state,
            inspirationImageURL: [...state.inspirationImageURL, url],
          };
        default:
          return state;
      }
    },
    setUploadedImageFileName: (state, action) => {
      const { tab, fileName } = action.payload;
      switch (tab) {
        case "Brand":
          return {
            ...state,
            brandImageFileName: fileName,
          };
        case "Product":
          return {
            ...state,
            productImageFileName: [
              ...state.productImageFileName,
              fileName,
            ]
          };
        case "Campaign":
          return {
            ...state,
            campaignImageFileName: fileName,
          };
        case "Inspiration":
          return {
            ...state,
            inspirationImageFileName: [
              ...state.inspirationImageFileName,
              fileName,
            ],
          };
        default:
          return state;
      }
    },
    
    removeUploadedImage: (state, action) => {
      const { tab, index, productName } = action.payload;
    
      switch (tab) {
        case "Product":
          if (index !== undefined && index !== null) {
            // Remove image by index if provided
            if (index < state.productImageFileName.length && index < state.productImageURL.length) {
              const newProductImagesByIndex = state.productImageFileName.filter((_, i) => i !== index);
              const newProductImagesURLByIndex = state.productImageURL.filter((_, i) => i !== index);
              return {
                ...state,
                productImageFileName: newProductImagesByIndex,
                productImageURL: newProductImagesURLByIndex,
              };
            } else {
              return state; // If index is out of range, return the original state
            }
          } else if (productName) {
            // Remove image by product name if provided
            const imageIndex = state.productImageFileName.findIndex(fileName => fileName === productName);
            
            if (imageIndex > -1) {
              const newProductImagesByName = state.productImageFileName.filter((_, i) => i !== imageIndex);
              const newProductImageURLByName = state.productImageURL.filter((_, i) => i !== imageIndex);
              return {
                ...state,
                productImageFileName: newProductImagesByName,
                productImageURL: newProductImageURLByName,
              };
            } else {
              return state; // If product name is not found, return the original state
            }
          }
          return state; // Return state if neither index nor productName is provided
    
        case "Inspiration":
            return {
              ...state,
              inspirationImageFileName: state.inspirationImageFileName.filter(
                (_, i) => i !== index
              ),
              inspirationImageURL: state.inspirationImageURL.filter(
                (_, i) => i !== index
              ),
            };
    
        default:
          return state;
      }
    },
    
    
    setEmptyProductImages: (state, action) => ({
      ...state,
      productImageFileName: [],
      productImageURL: [],
      blobProductImageURL: [],
      productSelectedVal : []
    }),
    
    
    setEmptyInsipirationImages: (state, action) => ({
      ...state,
      inspirationImageFileName: [],
      inspirationImageURL: [],
    }),
    setBriefId: (state, action) => ({
      ...state,
      briefId: action.payload,
    }),
    setBriefData: (state, action) => ({
      ...state,
      briefData: action.payload,
    }),
    setIsLoading: (state, action) => ({
      ...state,
      isLoading: action.payload,
    }),
    setIsViewPreviousBriefModalOpen: (state, action) => ({
      ...state,
      isViewPreviousBriefModalOpen: action.payload,
    }),
    setUserName: (state, action) => ({
      ...state,
      userName: action.payload,
    }),
    setUserEmail: (state, action) => ({
      ...state,
      userEmail: action.payload,
    }),
    setMultipleSelectedProducts: (state, action) => ({
      ...state,
      multipleSelectedProducts: action.payload,
    }),
    setIsAlertMessageOpen: (state, action) => ({
      ...state,
      isAlertMessageOpen: action.payload,
    }),
    setAlertMessageText:(state,action)=>({
      ...state,
      alertMessageText:action.payload
    })
  },
});

// Action creators are generated for each case reducer function
export const {
  setTastewiseSelectionValue,
  setTrendingRecipeTastewiseOthersSelectedVal,
  setTrendingRecipeTastewiseSelectedVal,
  setTrendingRecipeTastewiseOptions,
  setTrendingRecipeTastewiseIndexScoreSelectedVal,
  setTopDishSelectedVal,
  setTopDishOptions,
  setTrendingRecipeOthersSelectedVal,
  setTrendingRecipeSelectedVal,
  setTrendingRecipeOptions,
  setProductOthersSelectedStatus,
  setProductOthersSelectedVal,
  setProductSelectedVal,
  setProductOptions,
  setProductDescriptionOthersSelectedVal,
  setProductDescriptionSelectedVal,
  setUserPromptSelectedVal,
  setMarketSelectedVal,
  setMarketOptions,
  setBrandSelectedVal,
  setBrandOptions,
  setTopDishTastewiseSelectedVal,
  setIsBlobImageLoading,
  setUploadedImage,
  setUploadedImageURL,
  setUploadedImageFileName,
  removeUploadedImage,
  setEmptyInsipirationImages,
  setBriefId,
  setBriefData,
  setIsLoading,
  setIsViewPreviousBriefModalOpen,
  setUserName,
  setBlobProductImageURL,
  setProductImageURL,
  setEmptyProductImages,
  setUserEmail,
  setIsTastewiseFeatureRequired,
  setMultipleSelectedProducts,
  setIsAlertMessageOpen,
  setAlertMessageText
} = createBriefSlicer.actions;

export default createBriefSlicer.reducer