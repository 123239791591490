import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import pptxgen from "pptxgenjs";
import { Box } from "@mui/material";
import { getProductImageKey } from "../../Constant/ProductImages";
import { removeIndexScore } from "../../utilities/stringUtils";
import { getSelectedValue } from "../../utilities/common/commonUtils";
import { brandOptions } from "../../Constant/PreviewPPTLayoutInfo";
import "../../assets/styles/fonts.css";
import { getImageUrl } from "../../utilities/apiUtils/apiUtils";
import {
  campaignBackgroundText,
  campaignKeyMessageText,
  contentMandatoriesText,
  deliverablesText,
  handleText,
  hashtagsText,
  inspirationText,
  linkToUseText,
  postingTimelineText,
  socialMediaHandlesText,
  suggestedCaptionsText,
  theAskText,
  thoughtStarterText,
} from "../../Constant/EditBrief";
import { useTextLabels } from "../../Constant/useTextLabels";

const imageContext = require.context("../../assets/images", true);

function PPTGeneratorKnorr() {
  const {
    aboutBrand,
    aboutProduct,
    campaignOverview,
    campaignGoal,
    captionIdeas,
    suggestedHeadlines,
    contentMandatories,
    socialMediaHashtag,
    hashtags,
    linkToUse,
    postingTimeline,
    deliverables,
    pptDownloadName,
    inspirationVideoLink,
  } = useSelector((state) => state.editYourBrief);
  const {
    // brandOptions,
    blobProductImageURL,
    brandSelectedVal,
    topDishSelectedVal,
    trendingRecipeTastewiseSelectedVal,
    productSelectedVal,
    productOthersSelectedVal,
    topDishTastewiseSelectedVal,
    trendingRecipeTastewiseOptions,
    trendingRecipeSelectedVal,
    tastewiseSelectionValue,
    marketSelectedVal,
    productImageURL,
    brandImageURL,
    campaignImageURL,
    inspirationImageURL,
    trendingRecipeTastewiseOthersSelectedVal,
    trendingRecipeOthersSelectedVal,
  } = useSelector((state) => state.createBrief);

  const labels = useTextLabels();

  const calculateNewDimensions = (imgUrl, imageCount) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = imgUrl;
  
      img.onload = () => {
        const imgWidth = img.width / 96; // Convert pixels to inches
        const imgHeight = img.height / 96;
  
        let maxSlideWidth = 4.3; // in inches
        let maxSlideHeight = 3; // in inches
  
        // Adjust dimensions based on the number of images
        if (imageCount === 3) {
          maxSlideWidth /= 2; // Half width for the first two images
          maxSlideHeight /= 2; // Half height for the first two images
        } else if (imageCount === 2) {
          maxSlideWidth /= 2; // Half width for two images
        }
  
        // Initial values
        let newWidth = imgWidth;
        let newHeight = imgHeight;
  
        // Check if the image dimensions exceed the maximum allowed dimensions
        if (newWidth > maxSlideWidth || newHeight > maxSlideHeight) {
          const scale = Math.min(
            maxSlideWidth / newWidth,
            maxSlideHeight / newHeight
          );
          newWidth *= scale;
          newHeight *= scale;
        }
  
       
        resolve({ newWidth, newHeight });
      };
  
      img.onerror = () => {
        reject(new Error("Error loading image"));
      };
    });
  };
  
  const generatePPT = async () => {
    // Container width used for bullet points
    const containerWidth = 266.4;
    const containerWidthSlide5 = 274;
    // const containerWidth = 0.185 * window.innerWidth;

    // Function to calculate text width
    function getTextWidth(text, font) {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      context.font = font;
      const metrics = context.measureText(text);
      return metrics.width;
    }

    const ppt = new pptxgen();
    const slide1 = ppt.addSlide();

    const filteredBrandInfo = brandOptions.filter(
      (brand) => brand.brand_name === brandSelectedVal
    );

    // this object contains brand related properties like font, color, brackground etc
    const selectedBrandInfo = filteredBrandInfo[0];
    const template = selectedBrandInfo.templateType;

    // Dynamically import logo based on brandSelectedVal
    const logoPath = imageContext(
      `./${brandSelectedVal}/${selectedBrandInfo.logo}`
    );

    // Dynamically import image based on brandSelectedVal
    const slide1BackgroundImagePath = imageContext(
      `./${brandSelectedVal}/${selectedBrandInfo.slide1.backgroundImage}`
    );

    slide1.addImage({
      path: slide1BackgroundImagePath,
      x: 0,
      y: 0,
      w: "100%",
      h: "100%",
      sizing: { type: "cover" },
    });

    const slide1SubHeadingBackgroundImagePath = imageContext(
      `./${brandSelectedVal}/${selectedBrandInfo.slide1.subHeadingBackgroundImage}`
    );

    if (template === "template1") {
      slide1.addImage({
        path: logoPath,
        x: "73%",
        y: "7%",
        w: "20%",
        h: "30%",
        sizing: { type: "cover" },
        align: "center",
      });

      slide1.addImage({
        path: slide1SubHeadingBackgroundImagePath,
        x: "3%",
        y: "35%",
        w: "60%",
        h: "65%",
        sizing: { type: "cover" },
      });

      slide1.addText(`${brandSelectedVal} Creator`, {
        x: "8%",
        y: "67%",
        fontSize: selectedBrandInfo.slide1.headingFontSize,
        fontFace: selectedBrandInfo.fontFamily,
        bold: true,
        color: selectedBrandInfo.slide1.headingFontColor,
        align: "left",
      });

      slide1.addText("Briefing", {
        x: "8%",
        y: "79%",
        fontSize: selectedBrandInfo.slide1.headingFontSize,
        fontFace: selectedBrandInfo.fontFamily,
        bold: true,
        color: selectedBrandInfo.slide1.headingFontColor,
        align: "left",
      });

      slide1.addText(
        getSelectedValue(
          tastewiseSelectionValue,
          trendingRecipeTastewiseSelectedVal,
          trendingRecipeTastewiseOthersSelectedVal,
          trendingRecipeSelectedVal,
          trendingRecipeOthersSelectedVal
        ),
        {
          x: "8%",
          y: "91%",
          fontSize: selectedBrandInfo.slide1.subHeadingFontSize,
          fontFace: selectedBrandInfo.fontFamily,
          bold: true,
          color: selectedBrandInfo.slide1.headingFontColor,
          align: "left",
        }
      );
    } else if (template === "template2") {
      slide1.addImage({
        path: slide1SubHeadingBackgroundImagePath,
        x: "0%",
        y: "65%",
        w: "100%",
        h: "35%",
        sizing: { type: "cover" },
      });

      slide1.addText(`${brandSelectedVal} Creator Briefing`, {
        x: "0%",
        y: "69%",
        w: "100%",
        fontSize: selectedBrandInfo.slide1.headingFontSize,
        fontFace: selectedBrandInfo.fontFamily,
        bold: true,
        color: selectedBrandInfo.slide1.headingFontColor,
        align: "center",
      });

      const coverLogoPath = imageContext(
        `./${brandSelectedVal}/${selectedBrandInfo.slide1.coverLogo}`
      );

      slide1.addImage({
        path: coverLogoPath,
        x: "43%",
        y: "74%",
        w: "15%",
        h: "12%",
        sizing: {
          type: "cover",
          // w: "50%",
          // h: "30%"
        },
      });

      slide1.addText(
        getSelectedValue(
          tastewiseSelectionValue,
          trendingRecipeTastewiseSelectedVal,
          trendingRecipeTastewiseOthersSelectedVal,
          trendingRecipeSelectedVal,
          trendingRecipeOthersSelectedVal
        ),
        {
          x: "0%",
          y: "89%",
          w: "100%",
          fontSize: selectedBrandInfo.slide1.subHeadingFontSize,
          fontFace: selectedBrandInfo.fontFamily,
          bold: true,
          color: selectedBrandInfo.slide1.headingFontColor,
          align: "center",
        }
      );
    }

    const slide2 = ppt.addSlide();

    // Helper function to get X position for placement
    const getXForPlacement = (imageObject) => {
      const placement = imageObject?.placement || "default";
      return `${imageObject?.[placement]?.x || "0"}%`;
    };

    // Background Image Left
    if (selectedBrandInfo?.slide2?.backgroundImageLeft?.path) {
      const slide2BackgroundImageLeftPath = imageContext(
        `./${brandSelectedVal}/${selectedBrandInfo.slide2.backgroundImageLeft.path}`
      );
      slide2.addImage({
        path: slide2BackgroundImageLeftPath,
        x: getXForPlacement(selectedBrandInfo.slide2.backgroundImageLeft),
        y: "0%",
        w: "50%",
        h: "100%",
        sizing: { type: "cover" },
      });
    }

    // Cover Image Left
    if (selectedBrandInfo?.slide2?.coverImageLeft?.path) {
      const slide2CoverImageLeftPath = imageContext(
        `./${brandSelectedVal}/${selectedBrandInfo.slide2.coverImageLeft.path}`
      );
      slide2.addImage({
        path: slide2CoverImageLeftPath,
        x: getXForPlacement(selectedBrandInfo.slide2.coverImageLeft),
        y: "0%",
        w: "50%",
        h: "100%",
        sizing: { type: "cover" },
      });
    }

    // Background Image Right
    if (selectedBrandInfo?.slide2?.backgroundImageRight?.path) {
      const slide2BackgroundImageRightPath = imageContext(
        `./${brandSelectedVal}/${selectedBrandInfo.slide2.backgroundImageRight.path}`
      );
      slide2.addImage({
        path: slide2BackgroundImageRightPath,
        x: getXForPlacement(selectedBrandInfo.slide2.backgroundImageRight),
        y: "0%",
        w: "50%",
        h: "100%",
        sizing: { type: "cover" },
      });
    }

    // Cover Image Right
    if (selectedBrandInfo?.slide2?.coverImageRight?.path) {
      const slide2CoverImageRightPath = imageContext(
        `./${brandSelectedVal}/${selectedBrandInfo.slide2.coverImageRight.path}`
      );
      slide2.addImage({
        path: slide2CoverImageRightPath,
        x: getXForPlacement(selectedBrandInfo.slide2.coverImageRight),
        y: "61%",
        sizing: { type: "cover" },
      });
    }

    // Illustration Image Bottom Left
    if (selectedBrandInfo?.slide2?.illustrationBottomLeft?.path) {
      const slide2IllustrationImageBottomLeftPath = imageContext(
        `./${brandSelectedVal}/${selectedBrandInfo.slide2.illustrationBottomLeft.path}`
      );
      slide2.addImage({
        path: slide2IllustrationImageBottomLeftPath,
        x: getXForPlacement(selectedBrandInfo.slide2.illustrationBottomLeft),
        y: "82%",
        sizing: { type: "cover" },
      });
    }

    // Illustration Image Bottom Right
    if (selectedBrandInfo?.slide2?.illustrationBottomRight?.path) {
      const slide2IllustrationImageBottomRightPath = imageContext(
        `./${brandSelectedVal}/${selectedBrandInfo.slide2.illustrationBottomRight.path}`
      );
      slide2.addImage({
        path: slide2IllustrationImageBottomRightPath,
        x: getXForPlacement(selectedBrandInfo.slide2.illustrationBottomRight),
        y: "82%",
        sizing: { type: "cover" },
      });
    }

    const logoWidth = "10%";
    const logoHeight = "8.79%";
    const textWidth = "50%";

    // Add logo if available
    if (logoPath) {
      slide2.addImage({
        path: logoPath,
        x: "90%",
        y: "2%",
        sizing: { type: "contain", w: logoWidth, h: logoHeight },
      });
    }

    // Add text if available
    if (
      selectedBrandInfo?.slide2?.headingFontSize &&
      selectedBrandInfo?.slide2?.headingFontColor
    ) {
      slide2.addText(`${labels.ALL_ABOUT} ${brandSelectedVal}`, {
        x: getXForPlacement(selectedBrandInfo.slide2.aboutBrandDesc),
        y: "5%",
        fontSize: selectedBrandInfo.slide2.headingFontSize,
        fontFace: selectedBrandInfo.fontFamily,
        bold: true,
        color: selectedBrandInfo.slide2.headingFontColor,
        align: "left",
        valign: "top",
        w: textWidth,
      });
    }

    if (
      aboutBrand &&
      selectedBrandInfo?.slide2?.subHeadingFontSize &&
      selectedBrandInfo?.slide2?.subHeadingFontColor
    ) {
      slide2.addText(aboutBrand, {
        x: getXForPlacement(selectedBrandInfo.slide2.aboutBrandText),
        y: "35%",
        w: textWidth,
        fontFace: selectedBrandInfo.fontFamily,
        fontSize: selectedBrandInfo.slide2.subHeadingFontSize,
        color: selectedBrandInfo.slide2.subHeadingFontColor,
        align: "left",
        valign: "middle",
      });
    }

    const slide3 = ppt.addSlide();

    // Check and add Background Image Left
    if (selectedBrandInfo?.slide3?.backgroundImageLeft?.path) {
      const slide3BackgroundImageLeftPath = imageContext(
        `./${brandSelectedVal}/${selectedBrandInfo.slide3.backgroundImageLeft.path}`
      );
      slide3.addImage({
        path: slide3BackgroundImageLeftPath,
        x: getXForPlacement(selectedBrandInfo.slide3.backgroundImageLeft),
        y: "0%",
        w: "50%",
        h: "100%",
        sizing: { type: "cover" },
      });
    }

    // Check and add Illustration Image Top Left
    if (selectedBrandInfo?.slide3?.illustrationTopLeft?.path) {
      const slide3IllustrationImageTopLeftPath = imageContext(
        `./${brandSelectedVal}/${selectedBrandInfo.slide3.illustrationTopLeft.path}`
      );
      slide3.addImage({
        path: slide3IllustrationImageTopLeftPath,
        x: getXForPlacement(selectedBrandInfo.slide3.illustrationTopLeft),
        y: "0%",
        sizing: { type: "cover" },
      });
    }

    // Add text for product name
    if (
      selectedBrandInfo?.slide3?.headingFontSize &&
      selectedBrandInfo?.slide3?.headingFontColor
    ) {
      slide3.addText(
        `${labels.ALL_ABOUT} ${
          productSelectedVal && productSelectedVal.map(product => product.product_name).join(', ')
        }`,
        {
          x: getXForPlacement(selectedBrandInfo.slide3.aboutProductText),
          y: "22%",
          w: "48%",
          fontSize: selectedBrandInfo.slide3.headingFontSize,
          bold: true,
          fontFace: selectedBrandInfo.fontFamily,
          color: selectedBrandInfo.slide3.headingFontColor,
        }
      );
    }

    // Add text for product description
    if (
      aboutProduct &&
      selectedBrandInfo?.slide3?.subHeadingFontSize &&
      selectedBrandInfo?.slide3?.subHeadingFontColor
    ) {
      slide3.addText(aboutProduct, {
        x: getXForPlacement(selectedBrandInfo.slide3.aboutProductDesc),
        y: "57%",
        w: "50%",
        align: "left",
        fontFace: selectedBrandInfo.fontFamily,
        fontSize: selectedBrandInfo.slide3.subHeadingFontSize,
        color: selectedBrandInfo.slide3.subHeadingFontColor,
      });
    }

    // Check and add Illustration Image Bottom Right
    if (selectedBrandInfo?.slide3?.illustrationBottomRight?.path) {
      const slide3IllustrationImageBottomRightPath = imageContext(
        `./${brandSelectedVal}/${selectedBrandInfo.slide3.illustrationBottomRight.path}`
      );
      slide3.addImage({
        path: slide3IllustrationImageBottomRightPath,
        x: getXForPlacement(selectedBrandInfo.slide3.illustrationBottomRight),
        y: "82%",
        sizing: { type: "cover" },
      });
    }

    // Check and add Background Image Right
    if (selectedBrandInfo?.slide3?.backgroundImageRight?.path) {
      const slide3BackgroundImageRightPath = imageContext(
        `./${brandSelectedVal}/${selectedBrandInfo.slide3.backgroundImageRight.path}`
      );
      slide3.addImage({
        path: slide3BackgroundImageRightPath,
        x: getXForPlacement(selectedBrandInfo.slide3.backgroundImageRight),
        y: "0%",
        w: "50%",
        h: "100%",
        sizing: { type: "cover" },
      });
    }

    const productImageURLs = Array.isArray(productImageURL)
      ? productImageURL
      : [productImageURL || blobProductImageURL];

    const imageDimensions = await Promise.all(
      productImageURLs.map((url) =>
        calculateNewDimensions(url, productImageURLs.length)
      )
    );
    const imagePositions = [];

    console.log(imageDimensions, "222222");

    // Set positions based on the number of images
    const imageCount = imageDimensions.length;

    if (imageCount === 1) {
      // Center align the single image
      const { newWidth, newHeight } = imageDimensions[0];
     
      imagePositions.push({
        x: imageDimensions[0].newWidth < 1.5 ? "70%" : "55%", // Center
        y: imageDimensions[0].newHeight < 1.5 ? "35%" : "25%", // Adjust as needed
        w: imageDimensions[0].newWidth,
        h: imageDimensions[0].newHeight,
      });
    } else if (imageCount === 2) {
      // Flex layout for two images
      const spacing = 10; // Space between images in percent
      const totalWidth = 100 - spacing; // Total width available for two images
      const imageWidth = totalWidth / 2;

      imagePositions.push(
        {
          x: imageDimensions[0].newWidth < 1.5 ? "60%" : "52%",
          y: imageDimensions[0].newHeight < 1.5 ? "35%" : "25%",
          w: imageDimensions[0].newWidth,
          h: imageDimensions[0].newHeight,
        },
        {
          x:  imageDimensions[1].newWidth < 1.6 ? "80%" : "75%",
          y: imageDimensions[1].newHeight < 1.5 ? "35%" : "25%",
          w: imageDimensions[1].newWidth,
          h: imageDimensions[1].newHeight,
        }
      );
    } else if (imageCount === 3) {
      // Two images on the first row and one on the second
    
     

      imagePositions.push(
        {
          x: imageDimensions[0].newWidth < 1.6 ? "60%" : "52%",
          y: "20%",
          w: imageDimensions[0].newWidth,
          h: imageDimensions[0].newHeight,
        }, // First image
        {
          x: imageDimensions[1].newWidth < 1.6 ? "80%" : "75%",
          y: "20%",
          w: imageDimensions[1].newWidth,
          h: imageDimensions[1].newHeight,
        }, // Second image
        {
          x: "67%",
          y: "50%",
          w: imageDimensions[2].newWidth,
          h: imageDimensions[2].newHeight,
        } // Third image centered below
      );
    }

    // Add images to slide3
    imagePositions.forEach((position, index) => {
      slide3.addImage({
        path: productImageURLs[index],
        x: position.x,
        y: position.y,
        w: position.w, // Use calculated width
        h: position.h, // Use calculated height
      });
    });

    // Add logo if available
    if (logoPath) {
      slide3.addImage({
        path: logoPath,
        x: "90%",
        y: "2%",
        sizing: { type: "contain", w: logoWidth, h: logoHeight },
      });
    }
    const slide4 = ppt.addSlide();

    const slide4BackgroundImageLeftX = getXForPlacement(
      selectedBrandInfo.slide4?.backgroundImageLeft
    );
    const slide4BackgroundImageRightX = getXForPlacement(
      selectedBrandInfo.slide4?.backgroundImageRight
    );
    const slide4IllustrationBottomLeftX = getXForPlacement(
      selectedBrandInfo.slide4?.illustrationBottomLeft
    );
    const slide4IllustrationBottomRightX = getXForPlacement(
      selectedBrandInfo.slide4?.illustrationBottomRight
    );
    const slide4AboutProductTextX = getXForPlacement(
      selectedBrandInfo.slide4?.aboutCampaignText
    );
    const slide4AboutProductDescX = getXForPlacement(
      selectedBrandInfo.slide4?.aboutCampaignDesc
    );
    const slide4CampaignImageX = getXForPlacement(
      selectedBrandInfo.slide4?.defaultCoverImageLeft
    );

    // Check and add Background Image Left
    if (selectedBrandInfo?.slide4?.backgroundImageLeft?.path) {
      const slide4BackgroundImageLeftPath = imageContext(
        `./${brandSelectedVal}/${selectedBrandInfo.slide4.backgroundImageLeft.path}`
      );
      slide4.addImage({
        path: slide4BackgroundImageLeftPath,
        x: slide4BackgroundImageLeftX,
        y: "0%",
        w: "50%",
        h: "100%",
        sizing: { type: "cover" },
      });
    }

    // Check and add Default Cover Image Left
    if (selectedBrandInfo?.slide4?.defaultCoverImageLeft?.path) {
      const slide4DefaultCoverImageLeftPath = imageContext(
        `./${brandSelectedVal}/${selectedBrandInfo.slide4.defaultCoverImageLeft.path}`
      );
      const { newWidth: campaignImageWidth, newHeight: campaignImageHeight } =
        await calculateNewDimensions(
          campaignImageURL || slide4DefaultCoverImageLeftPath
        );
      let campaignX, campaignY;

      if (campaignImageWidth === campaignImageHeight) {
        campaignX = "5%";
        campaignY = "20%";
      } else if (campaignImageWidth > campaignImageHeight) {
        campaignX = "3%";
        campaignY = "25%";
      } else {
        // height is greater than width
        campaignX = "10%";
        campaignY = "15%";
      }
      slide4.addImage({
        path: campaignImageURL || slide4DefaultCoverImageLeftPath,
        x: campaignX,
        y: campaignY,
        w: campaignImageWidth,
        h: campaignImageHeight,
        sizing: { type: "contain" },
      });
    }

    // Check and add Background Image Right
    if (selectedBrandInfo?.slide4?.backgroundImageRight?.path) {
      const slide4BackgroundImageRightPath = imageContext(
        `./${brandSelectedVal}/${selectedBrandInfo.slide4.backgroundImageRight.path}`
      );
      slide4.addImage({
        path: slide4BackgroundImageRightPath,
        x: slide4BackgroundImageRightX,
        y: "0%",
        w: "50%",
        h: "100%",
        sizing: { type: "cover" },
      });
    }

    // Add logo if available
    if (logoPath) {
      slide4.addImage({
        path: logoPath,
        x: "90%",
        y: "2%",
        sizing: { type: "contain", w: logoWidth, h: logoHeight },
      });
    }

    // Add text elements if available
    if (
      labels.CAMPAIGN_BACKGROUND &&
      selectedBrandInfo?.slide4?.headingFontSize &&
      selectedBrandInfo?.slide4?.headingFontColor
    ) {
      slide4.addText(labels.CAMPAIGN_BACKGROUND, {
        x: slide4AboutProductTextX,
        y: "6%",
        w: "48%",
        fontSize: selectedBrandInfo.slide4.headingFontSize,
        fontFace: selectedBrandInfo.fontFamily,
        bold: true,
        color: selectedBrandInfo.slide4.headingFontColor,
      });
    }

    if (
      campaignOverview &&
      selectedBrandInfo?.slide4?.subHeadingFontSize &&
      selectedBrandInfo?.slide4?.subHeadingFontColor
    ) {
      slide4.addText(campaignOverview, {
        x: slide4AboutProductTextX,
        y: "23%",
        w: "48%",
        fontSize: selectedBrandInfo.slide4.subHeadingFontSize,
        fontFace: selectedBrandInfo.fontFamily,
        bold: true,
        align: "left",
        color: selectedBrandInfo.slide4.subHeadingFontColor,
      });
    }

    if (
      theAskText &&
      selectedBrandInfo?.slide4?.headingFontSize &&
      selectedBrandInfo?.slide4?.headingFontColor
    ) {
      slide4.addText(labels.THE_ASK, {
        x: slide4AboutProductTextX,
        y: "40%",
        w: "48%",
        fontSize: selectedBrandInfo.slide4.headingFontSize,
        fontFace: selectedBrandInfo.fontFamily,
        bold: true,
        color: selectedBrandInfo.slide4.headingFontColor,
      });
    }

    if (
      campaignGoal &&
      selectedBrandInfo?.slide4?.subHeadingFontSize &&
      selectedBrandInfo?.slide4?.subHeadingFontColor
    ) {
      slide4.addText(campaignGoal, {
        x: slide4AboutProductTextX,
        y: "60%",
        w: "48%",
        fontSize: selectedBrandInfo.slide4.subHeadingFontSize,
        fontFace: selectedBrandInfo.fontFamily,
        bold: true,
        align: "left",
        color: selectedBrandInfo.slide4.subHeadingFontColor,
      });
    }

    // Check and add Illustration Image Bottom Left
    if (selectedBrandInfo?.slide4?.illustrationBottomLeft?.path) {
      const slide4IllustrationImageBottomLeftPath = imageContext(
        `./${brandSelectedVal}/${selectedBrandInfo.slide4.illustrationBottomLeft.path}`
      );
      slide4.addImage({
        path: slide4IllustrationImageBottomLeftPath,
        x: slide4IllustrationBottomLeftX,
        y: "82%",
        sizing: { type: "cover" },
      });
    }

    // Check and add Illustration Image Bottom Right
    if (selectedBrandInfo?.slide4?.illustrationBottomRight?.path) {
      const slide4IllustrationImageBottomRightPath = imageContext(
        `./${brandSelectedVal}/${selectedBrandInfo.slide4.illustrationBottomRight.path}`
      );
      slide4.addImage({
        path: slide4IllustrationImageBottomRightPath,
        x: slide4IllustrationBottomRightX,
        y: "82%",
        sizing: { type: "cover" },
      });
    }
    const slide5 = ppt.addSlide();

    // Function to handle path fetching with fallback
    const getImagePath = (imagePath) =>
      imagePath ? imageContext(`./${brandSelectedVal}/${imagePath}`) : null;

    // X positions with fallback
    const slide5BackgroundImageLeftX = getXForPlacement(
      selectedBrandInfo.slide5?.backgroundImageLeft
    );
    const slide5BackgroundImageRightX = getXForPlacement(
      selectedBrandInfo.slide5?.backgroundImageRight
    );
    const slide5HeadingTextX = getXForPlacement(
      selectedBrandInfo.slide5?.headingText
    );
    const slide5SubHeadingTextX = getXForPlacement(
      selectedBrandInfo.slide5?.subheadingText
    );
    const slide5ListContentTextX = getXForPlacement(
      selectedBrandInfo.slide5?.listContentText
    );
    const slide5CoverImageRightX = getXForPlacement(
      selectedBrandInfo.slide5?.coverImageRight
    );
    const slide5HeadlineTextX = getXForPlacement(
      selectedBrandInfo.slide5?.headlineText
    );

    // Paths with fallback
    const slide5BackgroundImageLeftPath = getImagePath(
      selectedBrandInfo.slide5?.backgroundImageLeft?.path
    );
    const slide5BackgroundImageRightPath = getImagePath(
      selectedBrandInfo.slide5?.backgroundImageRight?.path
    );
    const slide5CoverImageRightPath = getImagePath(
      selectedBrandInfo.slide5?.coverImageRight?.path
    );

  

    if (slide5BackgroundImageLeftPath) {
      slide5.addImage({
        path: slide5BackgroundImageLeftPath,
        x: slide5BackgroundImageLeftX,
        y: "0%",
        w: "50%",
        h: "100%",
        sizing: { type: "cover" },
      });
    }

    if (thoughtStarterText) {
      slide5.addText(labels.THUGHT_STARTER, {
        x: slide5HeadingTextX,
        y: "3%",
        fontSize: selectedBrandInfo.slide5?.headingFontSize,
        fontFace: selectedBrandInfo.fontFamily,
        bold: true,
        color: selectedBrandInfo.slide5?.headingFontColor,
      });
    }

    if (campaignKeyMessageText) {
      slide5.addText(labels.CAMPAIGN_KEY_MESSAGES, {
        x: slide5SubHeadingTextX,
        y: "10%",
        fontSize: selectedBrandInfo.slide5?.subHeadingFontSize,
        fontFace: selectedBrandInfo.fontFamily,
        bold: true,
        color: selectedBrandInfo.slide5?.subHeadingFontColor,
      });
    }

    // Initialize y position
    let yKeyMsg = 16;
    let yPreviousKeyMsg = 13;
    let numOfLinesKeyMsgPrevious = 1;
    let numOfLinesKeyMsgCurrent = 1;

    captionIdeas.forEach((idea, index) => {
      const textWidth = getTextWidth(
        idea,
        `${selectedBrandInfo.slide5?.mainContentFontSize} ${selectedBrandInfo.fontFamily}`
      );

      numOfLinesKeyMsgCurrent = Math.ceil(textWidth / containerWidthSlide5);

      if (numOfLinesKeyMsgCurrent === 1 && numOfLinesKeyMsgPrevious === 1) {
        yKeyMsg = yPreviousKeyMsg + 3;
      } else if (
        numOfLinesKeyMsgCurrent === 1 &&
        numOfLinesKeyMsgPrevious === 2
      ) {
        yKeyMsg = yPreviousKeyMsg + 4;
      } else if (
        numOfLinesKeyMsgCurrent === 1 &&
        numOfLinesKeyMsgPrevious === 3
      ) {
        yKeyMsg = yPreviousKeyMsg + 5;
      } else if (
        numOfLinesKeyMsgCurrent === 1 &&
        numOfLinesKeyMsgPrevious === 4
      ) {
        yKeyMsg = yPreviousKeyMsg + 6;
      } else if (
        numOfLinesKeyMsgCurrent === 1 &&
        numOfLinesKeyMsgPrevious === 5
      ) {
        yKeyMsg = yPreviousKeyMsg + 8;
      } else if (
        numOfLinesKeyMsgCurrent === 2 &&
        numOfLinesKeyMsgPrevious === 1
      ) {
        yKeyMsg = yPreviousKeyMsg + 4;
      } else if (
        numOfLinesKeyMsgCurrent === 2 &&
        numOfLinesKeyMsgPrevious === 2
      ) {
        yKeyMsg = yPreviousKeyMsg + 5;
      } else if (
        numOfLinesKeyMsgCurrent === 2 &&
        numOfLinesKeyMsgPrevious === 3
      ) {
        yKeyMsg = yPreviousKeyMsg + 6;
      } else if (
        numOfLinesKeyMsgCurrent === 2 &&
        numOfLinesKeyMsgPrevious === 4
      ) {
        yKeyMsg = yPreviousKeyMsg + 6;
      } else if (
        numOfLinesKeyMsgCurrent === 3 &&
        numOfLinesKeyMsgPrevious === 1
      ) {
        yKeyMsg = yPreviousKeyMsg + 5;
      } else if (
        numOfLinesKeyMsgCurrent === 3 &&
        numOfLinesKeyMsgPrevious === 2
      ) {
        yKeyMsg = yPreviousKeyMsg + 6.5;
      } else if (
        numOfLinesKeyMsgCurrent === 3 &&
        numOfLinesKeyMsgPrevious === 3
      ) {
        yKeyMsg = yPreviousKeyMsg + 7;
      } else if (
        numOfLinesKeyMsgCurrent === 3 &&
        numOfLinesKeyMsgPrevious === 4
      ) {
        yKeyMsg = yPreviousKeyMsg + 9;
      } else if (
        numOfLinesKeyMsgCurrent === 4 &&
        numOfLinesKeyMsgPrevious === 1
      ) {
        yKeyMsg = yPreviousKeyMsg + 6;
      } else if (
        numOfLinesKeyMsgCurrent === 4 &&
        numOfLinesKeyMsgPrevious === 3
      ) {
        yKeyMsg = yPreviousKeyMsg + 9;
      } else if (
        numOfLinesKeyMsgCurrent === 4 &&
        numOfLinesKeyMsgPrevious === 4
      ) {
        yKeyMsg = yPreviousKeyMsg + 10;
      } else if (
        numOfLinesKeyMsgCurrent === 4 &&
        numOfLinesKeyMsgPrevious === 5
      ) {
        yKeyMsg = yPreviousKeyMsg + 10;
      } else if (
        numOfLinesKeyMsgCurrent === 5 &&
        numOfLinesKeyMsgPrevious === 2
      ) {
        yKeyMsg = yPreviousKeyMsg + 9;
      } else if (
        numOfLinesKeyMsgCurrent === 5 &&
        numOfLinesKeyMsgPrevious === 4
      ) {
        yKeyMsg = yPreviousKeyMsg + 11;
      } else if (
        numOfLinesKeyMsgCurrent === 5 &&
        numOfLinesKeyMsgPrevious === 5
      ) {
        yKeyMsg = yPreviousKeyMsg + 11;
      } else {
        yKeyMsg = yPreviousKeyMsg + 8;
      }

      slide5.addText(idea, {
        x: slide5ListContentTextX,
        y: `${yKeyMsg}%`,
        w: "46%",
        h: 0.25,
        fontSize: selectedBrandInfo.slide5?.mainContentFontSize,
        fontFace: selectedBrandInfo.fontFamily,
        color: selectedBrandInfo.slide5?.mainContentFontColor,
        bullet: true,
        lineSpacing: 8,
      });

      // updating previous y position and number of lines
      yPreviousKeyMsg = yKeyMsg;
      numOfLinesKeyMsgPrevious = Math.ceil(textWidth / containerWidthSlide5);
    });

    if (suggestedCaptionsText) {
      slide5.addText(labels.SUGGESTED_CAPTIONS, {
        x: slide5SubHeadingTextX,
        y: `${yKeyMsg + 15}%`,
        fontSize: selectedBrandInfo.slide5?.subHeadingFontSize,
        fontFace: selectedBrandInfo.fontFamily,
        bold: true,
        color: selectedBrandInfo.slide5?.subHeadingFontColor,
      });
    }

    // Initialize y position for captions
    let yCaptions = yKeyMsg + 18;
    let yPreviousCaptions = yKeyMsg + 15;
    let numOfLinesCaptionsPrevious = 1;
    let numOfLinesCaptionsCurrent = 1;

    suggestedHeadlines.forEach((headline, index) => {
      const textWidth = getTextWidth(
        headline,
        `${selectedBrandInfo.slide5?.mainContentFontSize} ${selectedBrandInfo.fontFamily}`
      );

      numOfLinesCaptionsCurrent = Math.ceil(textWidth / containerWidthSlide5);

      if (numOfLinesCaptionsCurrent === 1 && numOfLinesCaptionsPrevious === 1) {
        yCaptions = yPreviousCaptions + 3;
      } else if (
        numOfLinesCaptionsCurrent === 1 &&
        numOfLinesCaptionsPrevious === 2
      ) {
        yCaptions = yPreviousCaptions + 4;
      } else if (
        numOfLinesCaptionsCurrent === 1 &&
        numOfLinesCaptionsPrevious === 3
      ) {
        yCaptions = yPreviousCaptions + 9;
      } else if (
        numOfLinesCaptionsCurrent === 1 &&
        numOfLinesCaptionsPrevious === 4
      ) {
        yCaptions = yPreviousCaptions + 6;
      } else if (
        numOfLinesCaptionsCurrent === 1 &&
        numOfLinesCaptionsPrevious === 5
      ) {
        yCaptions = yPreviousCaptions + 8;
      } else if (
        numOfLinesCaptionsCurrent === 2 &&
        numOfLinesCaptionsPrevious === 1
      ) {
        yCaptions = yPreviousCaptions + 4;
      } else if (
        numOfLinesCaptionsCurrent === 2 &&
        numOfLinesCaptionsPrevious === 2
      ) {
        yCaptions = yPreviousCaptions + 5;
      } else if (
        numOfLinesCaptionsCurrent === 2 &&
        numOfLinesCaptionsPrevious === 3
      ) {
        yCaptions = yPreviousCaptions + 6;
      } else if (
        numOfLinesCaptionsCurrent === 3 &&
        numOfLinesCaptionsPrevious === 1
      ) {
        yCaptions = yPreviousCaptions + 6;
      } else if (
        numOfLinesCaptionsCurrent === 3 &&
        numOfLinesCaptionsPrevious === 2
      ) {
        yCaptions = yPreviousCaptions + 6.5;
      } else if (
        numOfLinesCaptionsCurrent === 3 &&
        numOfLinesCaptionsPrevious === 3
      ) {
        yCaptions = yPreviousCaptions + 9;
      } else if (
        numOfLinesCaptionsCurrent === 3 &&
        numOfLinesCaptionsPrevious === 4
      ) {
        yCaptions = yPreviousCaptions + 9;
      } else if (
        numOfLinesCaptionsCurrent === 4 &&
        numOfLinesCaptionsPrevious === 1
      ) {
        yCaptions = yPreviousCaptions + 6;
      } else if (
        numOfLinesCaptionsCurrent === 5 &&
        numOfLinesCaptionsPrevious === 2
      ) {
        yCaptions = yPreviousCaptions + 9;
      } else if (
        numOfLinesCaptionsCurrent === 5 &&
        numOfLinesCaptionsPrevious === 5
      ) {
        yCaptions = yPreviousCaptions + 9;
      } else if (
        numOfLinesCaptionsCurrent === 4 && numOfLinesCaptionsPrevious === 4
      ){
        yCaptions = yPreviousCaptions + 9;
      } else if (
        numOfLinesCaptionsCurrent === 4 && numOfLinesCaptionsPrevious === 3
      ){
        yCaptions = yPreviousCaptions + 9;
      }
      else{
        yCaptions = yPreviousCaptions + 6; 
      }

      slide5.addText(headline, {
        x: slide5ListContentTextX,
        y: `${yCaptions}%`,
        w: "46%",
        h: 0.25,
        fontSize: selectedBrandInfo.slide5?.mainContentFontSize,
        fontFace: selectedBrandInfo.fontFamily,
        color: selectedBrandInfo.slide5?.mainContentFontColor,
        bullet: true,
        lineSpacing: 8,
      });

      // updating previous y position and number of lines
      yPreviousCaptions = yCaptions;
      numOfLinesCaptionsPrevious = Math.ceil(textWidth / containerWidthSlide5);
    });

    if (slide5BackgroundImageRightPath) {
      slide5.addImage({
        path: slide5BackgroundImageRightPath,
        x: slide5BackgroundImageRightX,
        y: "0%",
        w: "50%",
        h: "100%",
        sizing: { type: "cover" },
      });
    }

    if (logoPath) {
      slide5.addImage({
        path: logoPath,
        x: "90%",
        y: "2%",
        sizing: { type: "contain", w: logoWidth, h: logoHeight },
      });
    }

    if (slide5CoverImageRightPath) {
      slide5.addImage({
        path: slide5CoverImageRightPath,
        x: slide5CoverImageRightX,
        y: "25%",
        sizing: { type: "cover", w: "45%", h: "45%" },
      });
    }

    if (selectedBrandInfo.slide5?.disclaimerHeadingFontSize) {
      slide5.addText(
        `*These caption ideas are here to inspire you. We encourage you to reword in your tone of voice and align to your idea`,
        {
          x: slide5HeadlineTextX,
          y: "85%",
          h: "6.5%",
          w: "46%",
          fontSize: selectedBrandInfo.slide5?.disclaimerHeadingFontSize,
          fontFace: selectedBrandInfo.fontFamily,
          bold: true,
          color: selectedBrandInfo.slide5?.disclaimerHeadingFontColor,
          fill: selectedBrandInfo.slide5?.disclaimerHeadingFillColor,
        }
      );
    }
    const slide6 = ppt.addSlide();

    // Dynamically import background image based on brandSelectedVal
    const slide6BackgroundImagePath = imageContext(
      `./${brandSelectedVal}/${selectedBrandInfo.slide6?.backgroundImage}`
    );

    if (slide6BackgroundImagePath) {
      slide6.addImage({
        path: slide6BackgroundImagePath,
        x: 0,
        y: 0,
        w: "100%",
        h: "100%",
        color: "ffffff",
        sizing: { type: "cover" },
      });
    }

    if (inspirationText) {
      slide6.addText(labels.INSPIRATION, {
        x: "2%",
        y: "3%",
        fontSize: selectedBrandInfo.slide6?.headingFontSize,
        fontFace: selectedBrandInfo.fontFamily,
        bold: true,
        color: selectedBrandInfo.slide6?.headingFontColor,
      });
    }

    // Calculate the number of images
    const numImages = inspirationImageURL.length;

    // Define the positioning and dimensions based on the number of images
    let xPos, yPos, width, height;

    if (numImages === 1) {
      xPos = "35%";
      yPos = "28%";
      width = "31%";
      height = "38%";
    } else if (numImages === 2) {
      xPos = ["17%", "53%"];
      yPos = ["28%", "28%"];
      width = "31%";
      height = "38%";
    } else if (numImages === 3) {
      xPos = ["3%", "35%", "68%"];
      yPos = ["28%", "28%", "28%"];
      width = "31%";
      height = "38%";
    } else if (numImages === 4) {
      xPos = ["10%", "57%", "10%", "57%"];
      yPos = ["7%", "7%", "50%", "50%"];
      width = "31%";
      height = "38%";
    } else if (numImages === 5) {
      xPos = ["3%", "35%", "67.5%", "17%", "49%"];
      yPos = ["7%", "7%", "7%", "50%", "50%"];
      width = "31%";
      height = "38%";
    } else if (numImages === 6) {
      xPos = ["3%", "35%", "67.5%", "3%", "35%", "67.5%"];
      yPos = ["7%", "7%", "7%", "50%", "50%", "50%"];
      width = "31%";
      height = "38%";
    }

    // Add the images to slide6
    inspirationImageURL.forEach((imageUrl, index) => {
      if (imageUrl) {
        let calculatedXPos = Array.isArray(xPos) ? xPos[index] : xPos;
        let calculatedYPos = Array.isArray(yPos) ? yPos[index] : yPos;

        slide6.addImage({
          path: imageUrl,
          x: calculatedXPos,
          y: calculatedYPos,
          w: width,
          h: height,
          sizing: { type: "contain" },
        });
      }
    });

    if (logoPath) {
      slide6.addImage({
        path: logoPath,
        x: "90%",
        y: "2%",
        sizing: { type: "contain", w: logoWidth, h: logoHeight },
      });
    }

    if (selectedBrandInfo.slide6?.illustrationBottomLeft) {
      const slide6IllustrationImageBottomLeftPath = imageContext(
        `./${brandSelectedVal}/${selectedBrandInfo.slide6?.illustrationBottomLeft}`
      );
      slide6.addImage({
        path: slide6IllustrationImageBottomLeftPath,
        x: "0%",
        y: "82%",
        sizing: { type: "cover" },
      });
    }

    if (selectedBrandInfo.slide6?.illustrationBottomRight) {
      const slide6IllustrationImageBottomRightPath = imageContext(
        `./${brandSelectedVal}/${selectedBrandInfo.slide6?.illustrationBottomRight}`
      );
      slide6.addImage({
        path: slide6IllustrationImageBottomRightPath,
        x: "89%",
        y: "82%",
        sizing: { type: "cover" },
      });
    }

    if (inspirationVideoLink) {
      slide6.addText(`Video Link:  ${inspirationVideoLink}`, {
        x: "2%",
        y: "90%",
        h: "5.5%",
        w: "96%",
        fontSize: selectedBrandInfo.slide6?.subHeadingFontSize,
        fontFace: selectedBrandInfo.fontFamily,
        color: selectedBrandInfo.slide6?.subHeadingFontColor,
        fill: selectedBrandInfo.slide6?.subHeadingFillColor,
      });
    }
    const slide7 = ppt.addSlide();

    const slide7BackgroundImageLeftX = getXForPlacement(
      selectedBrandInfo.slide7?.backgroundImageLeft
    );
    const slide7BackgroundImageRightX = getXForPlacement(
      selectedBrandInfo.slide7?.backgroundImageRight
    );
    const slide7IllustrationBottomLeftX = getXForPlacement(
      selectedBrandInfo.slide7?.illustrationBottomLeft
    );
    const slide7IllustrationBottomRightX = getXForPlacement(
      selectedBrandInfo.slide7?.illustrationBottomRight
    );
    const slide7HeadingTextX = getXForPlacement(
      selectedBrandInfo.slide7?.headingText
    );
    const slide7doListTextX = getXForPlacement(
      selectedBrandInfo.slide7?.doListText
    );
    const slide7doNoListTextX = getXForPlacement(
      selectedBrandInfo.slide7?.doNotListText
    );

    // Dynamically import image based on brandSelectedVal

    if (selectedBrandInfo.slide7?.backgroundImageLeft) {
      const slide7BackgroundImageLeftPath = imageContext(
        `./${brandSelectedVal}/${selectedBrandInfo.slide7?.backgroundImageLeft?.path}`
      );
      slide7.addImage({
        path: slide7BackgroundImageLeftPath,
        x: slide7BackgroundImageLeftX,
        y: "0%",
        w: "50%",
        h: "100%",
        sizing: { type: "cover" },
      });
    }

    if (contentMandatoriesText) {
      slide7.addText(labels.CONTENT_MANDATORIES, {
        x: slide7HeadingTextX,
        y: "3%",
        fontSize: selectedBrandInfo.slide7?.headingFontSize,
        fontFace: selectedBrandInfo.fontFamily,
        bold: true,
        color: selectedBrandInfo.slide7?.headingFontColor,
      });
    }

    if (selectedBrandInfo.slide7?.doListText) {
      slide7.addText(labels.DO_S, {
        x: slide7HeadingTextX,
        w: "48%",
        y: "8%",
        fontSize: selectedBrandInfo.slide7?.subHeadingFontSize,
        fontFace: selectedBrandInfo.fontFamily,
        bold: true,
        color: selectedBrandInfo.slide7?.subHeadingFontColor,
      });
    }

    // Separate 'Do's' and 'Don'ts' into different arrays
    const doItems = [];
    const dontItems = [];

    contentMandatories.forEach((mandatories) => {
      doItems.push(...mandatories.do);
      dontItems.push(...mandatories.donts);
    });

    // Initialize y position for 'Do's'
    let yDo = 10;
    let yPreviousDo = 7;

    let numOfLinesDoPrevious = 1;
    let numOfLinesDoCurrent = 1;

    // Check if each bullet point will wrap
    doItems.forEach((bullet) => {
      const textWidth = getTextWidth(
        bullet,
        `${selectedBrandInfo.slide7?.subHeadingFontSize} ${selectedBrandInfo.fontFamily}`
      );

      numOfLinesDoCurrent = Math.ceil(textWidth / containerWidth);

      // Add more space between y positions based on the number of lines
      if (numOfLinesDoCurrent === 1 && numOfLinesDoPrevious === 1) {
        yDo = yPreviousDo + 5;
      } else if (numOfLinesDoCurrent === 2 && numOfLinesDoPrevious === 1) {
        yDo = yPreviousDo + 6;
      } else if (numOfLinesDoCurrent === 2 && numOfLinesDoPrevious === 2) {
        yDo = yPreviousDo + 7;
      } else if (numOfLinesDoCurrent === 2 && numOfLinesDoPrevious === 3) {
        yDo = yPreviousDo + 7;
      } else if (numOfLinesDoCurrent === 2 && numOfLinesDoPrevious === 4) {
        yDo = yPreviousDo + 9.5;
      } else if (numOfLinesDoCurrent === 2 && numOfLinesDoPrevious === 5) {
        yDo = yPreviousDo + 9;
      } else if (numOfLinesDoCurrent === 3 && numOfLinesDoPrevious === 1) {
        yDo = yPreviousDo + 7;
      } else if (numOfLinesDoCurrent === 3 && numOfLinesDoPrevious === 2) {
        yDo = yPreviousDo + 8.5;
      } else if (numOfLinesDoCurrent === 3 && numOfLinesDoPrevious === 3) {
        yDo = yPreviousDo + 9;
      } else if (numOfLinesDoCurrent === 3 && numOfLinesDoPrevious === 4) {
        yDo = yPreviousDo + 11;
      } else if (numOfLinesDoCurrent === 4 && numOfLinesDoPrevious === 1) {
        yDo = yPreviousDo + 8;
      } else if (numOfLinesDoCurrent === 4 && numOfLinesDoPrevious === 2) {
        yDo = yPreviousDo + 10;
      } else if (numOfLinesDoCurrent === 5 && numOfLinesDoPrevious === 2) {
        yDo = yPreviousDo + 11;
      } else if (numOfLinesDoCurrent === 1 && numOfLinesDoPrevious === 2) {
        yDo = yPreviousDo + 6;
      } else if (numOfLinesDoCurrent === 1 && numOfLinesDoPrevious === 3) {
        yDo = yPreviousDo + 7;
      } else if (numOfLinesDoCurrent === 1 && numOfLinesDoPrevious === 5) {
        yDo = yPreviousDo + 10;
      } else {
        yDo = yPreviousDo + 9;
      }

      slide7.addText(bullet, {
        x: slide7doListTextX,
        y: yDo + "%",
        w: "46%",
        h: 0.25,
        fontSize: selectedBrandInfo.slide7?.subHeadingFontSize,
        fontFace: selectedBrandInfo.fontFamily,
        color: selectedBrandInfo.slide7?.doFontColor,
        bullet: true,
        lineSpacing: 10,
      });

      // updating previous y position and number of lines
      yPreviousDo = yDo;
      numOfLinesDoPrevious = Math.ceil(textWidth / containerWidth);
    });

    if (selectedBrandInfo.slide7?.backgroundImageRight) {
      const slide7BackgroundImageRightPath = imageContext(
        `./${brandSelectedVal}/${selectedBrandInfo.slide7?.backgroundImageRight?.path}`
      );

      slide7.addImage({
        path: slide7BackgroundImageRightPath,
        x: slide7BackgroundImageRightX,
        y: "0%",
        w: "50%",
        h: "100%",
        sizing: { type: "cover" },
      });
    }

    if (logoPath) {
      slide7.addImage({
        path: logoPath,
        x: "90%",
        y: "2%",
        sizing: { type: "contain", w: logoWidth, h: logoHeight },
      });
    }

    // Add 'Don'ts'
    if (selectedBrandInfo.slide7?.doNotListText) {
      slide7.addText(labels.DON_TS, {
        x: slide7doNoListTextX,
        w: "48%",
        y: "8%",
        fontSize: selectedBrandInfo.slide7?.subHeadingFontSize,
        fontFace: selectedBrandInfo.fontFamily,
        bold: true,
        color: selectedBrandInfo.slide7?.doNotFontColor,
      });
    }

    // Initialize y position for 'Don'ts'
    let yDont = 10;
    let yPreviousDont = 7;

    let numOfLinesDontPrevious = 1;
    let numOfLinesDontCurrent = 1;

    // Check if each bullet point will wrap
    dontItems.forEach((bullet) => {
      const textWidth = getTextWidth(
        bullet,
        `${selectedBrandInfo.slide7?.subHeadingFontSize} ${selectedBrandInfo.fontFamily}`
      );

      numOfLinesDontCurrent = Math.ceil(textWidth / containerWidth);

      if (numOfLinesDontCurrent === 1 && numOfLinesDontPrevious === 1) {
        yDont = yPreviousDont + 5;
      } else if (numOfLinesDontCurrent === 2 && numOfLinesDontPrevious === 1) {
        yDont = yPreviousDont + 6;
      } else if (numOfLinesDontCurrent === 2 && numOfLinesDontPrevious === 2) {
        yDont = yPreviousDont + 7;
      } else if (numOfLinesDontCurrent === 2 && numOfLinesDontPrevious === 3) {
        yDont = yPreviousDont + 7;
      } else if (numOfLinesDontCurrent === 3 && numOfLinesDontPrevious === 1) {
        yDont = yPreviousDont + 7;
      } else if (numOfLinesDontCurrent === 3 && numOfLinesDontPrevious === 2) {
        yDont = yPreviousDont + 8.5;
      } else if (numOfLinesDontCurrent === 3 && numOfLinesDontPrevious === 3) {
        yDont = yPreviousDont + 9;
      } else if (numOfLinesDontCurrent === 3 && numOfLinesDontPrevious === 4) {
        yDont = yPreviousDont + 11;
      } else if (numOfLinesDontCurrent === 4 && numOfLinesDontPrevious === 1) {
        yDont = yPreviousDont + 8;
      } else if (numOfLinesDontCurrent === 5 && numOfLinesDontPrevious === 2) {
        yDont = yPreviousDont + 11;
      } else if (numOfLinesDontCurrent === 1 && numOfLinesDontPrevious === 2) {
        yDont = yPreviousDont + 6;
      } else if (numOfLinesDontCurrent === 1 && numOfLinesDontPrevious === 3) {
        yDont = yPreviousDont + 7;
      } else if (numOfLinesDontCurrent === 1 && numOfLinesDontPrevious === 5) {
        yDont = yPreviousDont + 10;
      } else {
        yDont = yPreviousDont + 9;
      }

      slide7.addText(bullet, {
        x: slide7doNoListTextX,
        y: yDont + "%",
        w: "46%",
        h: 0.25,
        fontSize: selectedBrandInfo.slide7?.subHeadingFontSize,
        fontFace: selectedBrandInfo.fontFamily,
        color: selectedBrandInfo.slide7?.doNotFontColor,
        bullet: true,
        lineSpacing: 10,
      });

      // updating previous y position and number of lines
      yPreviousDont = yDont;
      numOfLinesDontPrevious = Math.ceil(textWidth / containerWidth);
    });

    if (selectedBrandInfo.slide7?.illustrationBottomLeft) {
      const slide7IllustrationImageBottomLeftPath = imageContext(
        `./${brandSelectedVal}/${selectedBrandInfo.slide7?.illustrationBottomLeft?.path}`
      );

      slide7.addImage({
        path: slide7IllustrationImageBottomLeftPath,
        x: slide7IllustrationBottomLeftX,
        y: "82%",
        sizing: { type: "cover" },
      });
    }

    if (selectedBrandInfo.slide7?.illustrationBottomRight) {
      const slide7IllustrationImageBottomRightPath = imageContext(
        `./${brandSelectedVal}/${selectedBrandInfo.slide7?.illustrationBottomRight?.path}`
      );
      slide7.addImage({
        path: slide7IllustrationImageBottomRightPath,
        x: slide7IllustrationBottomRightX,
        y: "82%",
        sizing: { type: "cover" },
      });
    }
    const slide8 = ppt.addSlide();

    // Background image paths and X positions
    const slide8BackgroundImageLeftPath = selectedBrandInfo.slide8
      ?.backgroundImageLeft?.path
      ? imageContext(
          `./${brandSelectedVal}/${selectedBrandInfo.slide8.backgroundImageLeft.path}`
        )
      : null;

    const slide8BackgroundImageLeftX = getXForPlacement(
      selectedBrandInfo.slide8?.backgroundImageLeft
    );
    const slide8BackgroundImageRightPath = selectedBrandInfo.slide8
      ?.backgroundImageRight?.path
      ? imageContext(
          `./${brandSelectedVal}/${selectedBrandInfo.slide8.backgroundImageRight.path}`
        )
      : null;

    const slide8BackgroundImageRightX = getXForPlacement(
      selectedBrandInfo.slide8?.backgroundImageRight
    );
    const slide8IllustrationBottomLeftPath = selectedBrandInfo.slide8
      ?.illustrationBottomLeft?.path
      ? imageContext(
          `./${brandSelectedVal}/${selectedBrandInfo.slide8.illustrationBottomLeft.path}`
        )
      : null;

    const slide8IllustrationBottomLeftX = getXForPlacement(
      selectedBrandInfo.slide8?.illustrationBottomLeft
    );
    const slide8IllustrationBottomMiddlePath = selectedBrandInfo.slide8
      ?.illustrationBottomMiddle?.path
      ? imageContext(
          `./${brandSelectedVal}/${selectedBrandInfo.slide8.illustrationBottomMiddle.path}`
        )
      : null;

    const slide8IllustrationBottomMiddleX = getXForPlacement(
      selectedBrandInfo.slide8?.illustrationBottomMiddle
    );
    const slide8IllustrationBottomRightPath = selectedBrandInfo.slide8
      ?.illustrationBottomRight?.path
      ? imageContext(
          `./${brandSelectedVal}/${selectedBrandInfo.slide8.illustrationBottomRight.path}`
        )
      : null;

    const slide8IllustrationBottomRightX = getXForPlacement(
      selectedBrandInfo.slide8?.illustrationBottomRight
    );
    const slide8DeliverableLinksTextX = getXForPlacement(
      selectedBrandInfo.slide8?.deliverableLinkHandlesText
    );
    const slide8PostingTimelineTextX = getXForPlacement(
      selectedBrandInfo.slide8?.postingTimelineText
    );
    const slide8PostingTimelineDateX = getXForPlacement(
      selectedBrandInfo.slide8?.postingTimelineDate
    );

    if (slide8BackgroundImageLeftPath) {
      slide8.addImage({
        path: slide8BackgroundImageLeftPath,
        x: slide8BackgroundImageLeftX,
        y: "0%",
        w: "50%",
        h: "100%",
        sizing: { type: "cover" },
      });
    }

    if (deliverablesText) {
      slide8.addText(labels.DELIVERABLES, {
        x: slide8DeliverableLinksTextX,
        y: "9%",
        fontSize: selectedBrandInfo.slide8?.deliverablesHeadingFontSize,
        fontFace: selectedBrandInfo.fontFamily,
        bold: true,
        color: selectedBrandInfo.slide8?.deliverablesHeadingFontColor,
      });
    }

    if (deliverables) {
      slide8.addText(deliverables, {
        x: slide8DeliverableLinksTextX,
        y: "15%",
        fontSize: selectedBrandInfo.slide8?.deliverablesSubHeadingFontSize,
        fontFace: selectedBrandInfo.fontFamily,
        bold: true,
        color: selectedBrandInfo.slide8?.deliverablesSubHeadingFontColor,
      });
    }

    if (socialMediaHandlesText) {
      slide8.addText(labels.SOCIAL_MEDIA_HANDLES_AND_HASHTAGS, {
        x: slide8DeliverableLinksTextX,
        y: "23%",
        fontSize: selectedBrandInfo.slide8?.socialMediaHeadingFontSize,
        fontFace: selectedBrandInfo.fontFamily,
        bold: true,
        color: selectedBrandInfo.slide8?.socialMediaHeadingFontColor,
        wrap:true,
        w:"50%"
      });
    }

    if (hashtagsText) {
      slide8.addText(labels.HASHTAGS, {
        x: slide8DeliverableLinksTextX,
        y: "30.5%",
        fontSize: selectedBrandInfo.slide8?.hashtagsHeadingFontSize,
        fontFace: selectedBrandInfo.fontFamily,
        color: selectedBrandInfo.slide8?.hashtagsHeadingFontColor,
      });
    }

    if (hashtags) {
      slide8.addText(hashtags, {
        x: slide8DeliverableLinksTextX,
        y: "43%",
        w: "38%",
        fontSize: selectedBrandInfo.slide8?.hashtagsSubHeadingFontSize,
        fontFace: selectedBrandInfo.fontFamily,
        color: selectedBrandInfo.slide8?.hashtagsSubHeadingFontColor,
      });
    }

    if (handleText) {
      slide8.addText(labels.HANDLES, {
        x: slide8DeliverableLinksTextX,
        y: "58%",
        fontSize: selectedBrandInfo.slide8?.handlesHeadingFontSize,
        fontFace: selectedBrandInfo.fontFamily,
        color: selectedBrandInfo.slide8?.handlesHeadingFontColor,
      });
    }

    if (socialMediaHashtag) {
      socialMediaHashtag.forEach((handle, index) => {
        Object.entries(handle).forEach(([platform, username]) => {
          slide8.addText(`${platform}: ${username}`, {
            x: slide8DeliverableLinksTextX,
            y: "63%",
            w: "38%",
            fontSize: selectedBrandInfo.slide8?.handlesSubHeadingFontSize,
            fontFace: selectedBrandInfo.fontFamily,
            color: selectedBrandInfo.slide8?.handlesSubHeadingFontColor,
          });
        });
      });
    }

    if (linkToUseText) {
      slide8.addText(labels.LINKS_TO_USE_WITHIN_CAPTIONS, {
        x: slide8DeliverableLinksTextX,
        y: "74%",
        fontSize: selectedBrandInfo.slide8?.linksToUseHeadingFontSize,
        fontFace: selectedBrandInfo.fontFamily,
        bold: true,
        color: selectedBrandInfo.slide8?.linksToUseHeadingFontColor,
        w:"50%"
      });
    }

    if (linkToUse) {
      slide8.addText(linkToUse, {
        x: slide8DeliverableLinksTextX,
        y: "81%",
        w: "38%",
        fontSize: selectedBrandInfo.slide8?.linksToUseSubHeadingFontSize,
        fontFace: selectedBrandInfo.fontFamily,
        color: selectedBrandInfo.slide8?.linksToUseSubHeadingFontColor,
      });
    }

    if (slide8IllustrationBottomLeftPath) {
      slide8.addImage({
        path: slide8IllustrationBottomLeftPath,
        x: slide8IllustrationBottomLeftX,
        y: "82%",
        sizing: { type: "cover" },
      });
    }

    if (slide8BackgroundImageRightPath) {
      slide8.addImage({
        path: slide8BackgroundImageRightPath,
        x: slide8BackgroundImageRightX,
        y: "0%",
        w: "50%",
        h: "100%",
        sizing: { type: "cover" },
      });
    }

    if (logoPath) {
      slide8.addImage({
        path: logoPath,
        x: "90%",
        y: "2%",
        sizing: { type: "contain", w: logoWidth, h: logoHeight },
      });
    }

    if (postingTimelineText) {
      slide8.addText(labels.POSTING_TIMELINES, {
        x: slide8PostingTimelineTextX,
        y: "9%",
        fontSize: selectedBrandInfo.slide8?.postingTimelineHeadingFontSize,
        fontFace: selectedBrandInfo.fontFamily,
        bold: true,
        color: selectedBrandInfo.slide8?.postingTimelineHeadingFontColor,
      });
    }

    if (postingTimeline) {
      Object.entries(postingTimeline).forEach(([key, value], index) => {
        slide8.addText(key, {
          x: slide8PostingTimelineTextX,
          y: `${20 + index * 5}%`,
          fontSize: selectedBrandInfo.slide8?.postingTimelineSubHeadingFontSize,
          fontFace: selectedBrandInfo.fontFamily,
          bold: true,
          color: selectedBrandInfo.slide8?.postingTimelineSubHeadingFontColor,
        });

        slide8.addText(value, {
          x: slide8PostingTimelineDateX,
          y: `${20 + index * 5}%`,
          w: "33%",
          fontSize: selectedBrandInfo.slide8?.postingTimelineSubHeadingFontSize,
          fontFace: selectedBrandInfo.fontFamily,
          bold: true,
          color: selectedBrandInfo.slide8?.postingTimelineSubHeadingFontColor,
        });
      });
    }

    if (slide8IllustrationBottomMiddlePath) {
      slide8.addImage({
        path: slide8IllustrationBottomMiddlePath,
        x: slide8IllustrationBottomMiddleX,
        y: "82%",
        sizing: { type: "cover" },
      });
    }

    if (slide8IllustrationBottomRightPath) {
      slide8.addImage({
        path: slide8IllustrationBottomRightPath,
        x: slide8IllustrationBottomRightX,
        y: "82%",
        sizing: { type: "cover" },
      });
    }
    const slide9 = ppt.addSlide();

    // Dynamically import image based on brandSelectedVal
    const slide9BackgroundImagePath = selectedBrandInfo.slide9?.backgroundImage
      ? imageContext(
          `./${brandSelectedVal}/${selectedBrandInfo.slide9.backgroundImage}`
        )
      : null;

    if (slide9BackgroundImagePath) {
      slide9.addImage({
        path: slide9BackgroundImagePath,
        x: 0,
        y: 0,
        w: "100%",
        h: "100%",
        color: "ffffff",
        sizing: { type: "cover" },
      });
    }

    const slide9IllustrationLogoPath = selectedBrandInfo.slide9?.coverImage
      ? imageContext(
          `./${brandSelectedVal}/${selectedBrandInfo.slide9?.coverImage}`
        )
      : null;

    if (slide9IllustrationLogoPath) {
      slide9.addImage({
        path: slide9IllustrationLogoPath,
        x: "36%",
        y: "26%",
        w: "30%",
        h: "27%",
        sizing: { type: "cover" },
      });
    }

    if (selectedBrandInfo.slide9?.headingFontSize) {
      slide9.addText(labels.THANK_YOU, {
        x: "0%",
        y: "58%",
        w: "100%",
        fontSize: selectedBrandInfo.slide9.headingFontSize,
        fontFace: selectedBrandInfo.fontFamily,
        bold: true,
        color: selectedBrandInfo.slide9.headingFontColor,
        align: "center",
      });
    }

    // Array of boolean values to control rendering of the image for each slide
    const shouldAddImageArray = [
      selectedBrandInfo.slide2?.watermark,
      selectedBrandInfo.slide3?.watermark,
      selectedBrandInfo.slide4?.watermark,
      selectedBrandInfo.slide5?.watermark,
      selectedBrandInfo.slide6?.watermark,
      selectedBrandInfo.slide7?.watermark,
      selectedBrandInfo.slide8?.watermark,
    ];

    // Assuming you have an array of slide objects like slide2, slide3, ..., slide8
    const slides = [slide2, slide3, slide4, slide5, slide6, slide7, slide8];
    const slideWatermarkImagePath = selectedBrandInfo.watermarkImage
      ? imageContext(
          `./${brandSelectedVal}/${selectedBrandInfo.watermarkImage}`
        )
      : null;

    // Iterate over slides and corresponding shouldAddImage values
    slides.forEach((slide, index) => {
      if (shouldAddImageArray[index] && slideWatermarkImagePath) {
        slide.addImage({
          path: slideWatermarkImagePath,
          x: "0%",
          y: "25%",
          w: "100%",
          h: "60%",
          sizing: { type: "contain" },
        });
      }
    });

    ppt.writeFile(`${pptDownloadName}.pptx`);
  };

  return (
    <div>
      <Box className="downloadPPTBtn" onClick={generatePPT}>
        Download
      </Box>
    </div>
  );
}

export default PPTGeneratorKnorr;
