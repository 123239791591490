import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import StandardButton from "../StandardButton/StandardButton";
import {
  handleFileInputImageChange,
  uploadImageUrl,
} from "../../utilities/apiUtils/apiUtils";
import {
  setUploadedImageFileName,
  setUploadedImageURL,
  removeUploadedImage,
  setIsAlertMessageOpen,
  setAlertMessageText,
} from "../../slicers/createBriefSlicer";
import "./styles.css";
import { CircularProgress } from "@mui/material";

const UploadImageModal = ({ open, onClose, tabSelected }) => {
  const [value, setValue] = React.useState(tabSelected || "Product");
  const [fileName, setFileName] = useState("");
  const inputFileRef = useRef(null);
  const dispatch = useDispatch();
  const {
    uploadedImageURL,
    brandImageFileName,
    productImageFileName,
    campaignImageFileName,
    inspirationImageFileName,
    brandSelectedVal,
    marketSelectedVal,
    productSelectedVal,
    isBlobImageLoading
  } = useSelector((state) => state.createBrief);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const cancelButtonStyle = {
    borderRadius: "4px",
    background: "#008651",
    color: "#FFF",
    textAlign: "center",
    fontFamily: "Unilever Shilling",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "20px",
    textTransform: "none",
  };

  const chooseButtonStyle = {
    borderRadius: "4px",
    background: "#074EE8",
    color: "#FCFCFC",
    textAlign: "center",
    fontFamily: "Unilever Shilling",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "20px",
    textTransform: "none",
  };

  const handleClose = () =>{
    if(tabSelected === "Product") {
      if(productSelectedVal.length === productImageFileName.length){
        onClose()
      }
      else{
        dispatch(setIsAlertMessageOpen(true))
        dispatch(setAlertMessageText(
          `Selected ${productSelectedVal && productSelectedVal.length} Products and Uploaded ${productImageFileName && productImageFileName.length} ${productImageFileName.length === 1 ? 'image' : 'images'}. Please Check/Wait until upload`
        ));
        
      }
    }
    else{
      onClose()
    }
  }

  const handleFileInputChange = async (e, tabName) => {
    let maxImages = 1;
    if (tabName === "Inspiration") {
      maxImages = 6;
    } else if (tabName === "Product") {
      maxImages = productSelectedVal.length;
    }
  // If the limit is exceeded in handleFileInputImageChange, return early
  const limitExceeded = handleFileInputImageChange(
    e,
    dispatch,
    tabName,
    tabName === "Inspiration" ? inspirationImageFileName : productImageFileName,
    maxImages
  );
  if (limitExceeded) return;
    setFileName(e.target.files[0].name);

    const selectedFile = e.target.files[0];
    if (selectedFile && tabName === "Product") {
      const container = "product-images";
      try {
        const result = await uploadImageUrl(
          container,
          marketSelectedVal.toLowerCase(),
          brandSelectedVal.toLowerCase(),
          selectedFile
        );
        console.log("Upload successful:", result);
      } catch (error) {
        console.error("Upload failed:", error);
      }
    }
  };

  const handleDeleteImage = (tab, index) => {
    if (tab === "Inspiration" || tab === "Product") {
      // this function will delete inspiration image - name, data when the corresponding delete icon is clicked
      dispatch(removeUploadedImage({ tab: tab, index: index }));
    } else {
      // For other tabs, set the image file name, url to an empty string
      dispatch(setUploadedImageFileName({ tab, fileName: "" }));
      dispatch(setUploadedImageURL({ tab, url: "" }));
    }
  };

  const renderContent = () => {
    switch (value) {
      case "Product":
        return (
          <>
            <Box
              border={"1px dashed"}
              borderColor="grey.500"
              p={2}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
                {isBlobImageLoading ? (
          <CircularProgress size={42} style={{ marginBottom: 8, color: "#47C8FF" }} />
        ) : (
          <CloudUploadIcon
            style={{ fontSize: 42, marginBottom: 8, color: "#47C8FF" }}
          />
        )}
              <Typography
                style={{
                  color: "#000",
                  textAlign: "center",
                  fontFamily: "Unilever Shilling",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "20px",
                }}
              >
                Upload Images
              </Typography>
              <Typography
                style={{
                  color: "#111111",
                  textAlign: "center",
                  fontFamily: "Unilever Shilling",
                  fontSize: "10px",
                  fontStyle: "italic",
                  fontWeight: 400,
                  lineHeight: "20px",
                }}
              >
                *For best results, use 1100 (width) pixel x 1100 (height) pixel
                sized images. If no image is uploaded, a default image will be
                added.
              </Typography>
            </Box>

            {productImageFileName &&
              productImageFileName.map((fileName, index) => (
                <Box
                  key={index}
                  border={"1px solid"}
                  borderColor="#F5F3F3"
                  borderRadius={2}
                  p={1}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  marginTop={1}
                  sx={{ borderLeft: "2px solid #47C8FF" }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontFamily: "Unilever Shilling",
                      fontSize: 12,
                      fontStyle: "italic",
                      fontWeight: 400,
                      lineHeight: "20px",
                      flex: 1,
                      marginRight: 10,
                    }}
                  >
                    {fileName}
                  </Typography>
                  <DeleteOutlineOutlinedIcon
                    style={{ cursor: "pointer", color: "#767676" }}
                    onClick={() => handleDeleteImage(value, index)}
                  />
                </Box>
              ))}
          </>
        );
      case "Campaign":
        return (
          <>
            <Box
              border={"1px dashed"}
              borderColor="grey.500"
              p={2}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <CloudUploadIcon
                style={{ fontSize: 42, marginBottom: 8, color: "#47C8FF" }}
              />
              <Typography
                style={{
                  color: "#000",
                  textAlign: "center",
                  fontFamily: "Unilever Shilling",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "20px",
                }}
              >
                Upload Images
              </Typography>
              <Typography
                style={{
                  color: "#111111",
                  textAlign: "center",
                  fontFamily: "Unilever Shilling",
                  fontSize: "10px",
                  fontStyle: "italic",
                  fontWeight: 400,
                  lineHeight: "20px",
                }}
              >
                *For best results, use 530 (Width) pixel x 600 (height) pixel
                sized images. Images of other sizes will be auto-sized
                (resolution will get impacted) to fit into this space.
              </Typography>
            </Box>
            {campaignImageFileName && (
              <Box
                border={"1px solid"}
                borderColor="#F5F3F3"
                borderRadius={2}
                p={1}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                marginTop={1}
                sx={{ borderLeft: "2px solid #47C8FF" }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontFamily: "Unilever Shilling",
                    fontSize: 12,
                    fontStyle: "italic",
                    fontWeight: 400,
                    lineHeight: "20px",
                    flex: 1,
                    marginRight: 10,
                  }}
                >
                  {campaignImageFileName}
                </Typography>
                <DeleteOutlineOutlinedIcon
                  style={{ cursor: "pointer", color: "#767676" }}
                  onClick={() => handleDeleteImage(value)}
                />
              </Box>
            )}
          </>
        );
      case "Inspiration":
        return (
          <>
            <Box
              border={"1px dashed"}
              borderColor="grey.500"
              p={2}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <CloudUploadIcon
                style={{ fontSize: 42, marginBottom: 8, color: "#47C8FF" }}
              />
              <Typography
                style={{
                  color: "#000",
                  textAlign: "center",
                  fontFamily: "Unilever Shilling",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "20px",
                }}
              >
                Upload Images
              </Typography>
              <Typography
                style={{
                  color: "#111111",
                  textAlign: "center",
                  fontFamily: "Unilever Shilling",
                  fontSize: "10px",
                  fontStyle: "italic",
                  fontWeight: 400,
                  lineHeight: "20px",
                }}
              >
                *For best results, use 380 (Width) pixel x 285 (height) pixel
                sized images. Images of other sizes will be auto-sized
                (resolution will get impacted) to fit into this space.
              </Typography>
            </Box>
            {inspirationImageFileName &&
              inspirationImageFileName.map((fileName, index) => (
                <Box
                  key={index}
                  border={"1px solid"}
                  borderColor="#F5F3F3"
                  borderRadius={2}
                  p={1}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  marginTop={1}
                  sx={{ borderLeft: "2px solid #47C8FF" }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontFamily: "Unilever Shilling",
                      fontSize: 12,
                      fontStyle: "italic",
                      fontWeight: 400,
                      lineHeight: "20px",
                      flex: 1,
                      marginRight: 10,
                    }}
                  >
                    {fileName}
                  </Typography>
                  <DeleteOutlineOutlinedIcon
                    style={{ cursor: "pointer", color: "#767676" }}
                    onClick={() => handleDeleteImage(value, index)}
                  />
                </Box>
              ))}
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Dialog open={open} maxWidth={"sm"} fullWidth onClose={handleClose}>
      <Box margin={"24px 21px 39px 24px"}>
        <DialogTitle className="title">
          <Box variant="h6" className="title-text">
            Select images
          </Box>
          <CloseIcon className="close-icon" onClick={handleClose} />
        </DialogTitle>
        <Box sx={{ width: "100%", typography: "body1" }}>
          <Tabs value={value} onChange={handleChange}>
            <Tab
              label="Product"
              value="Product"
              sx={{
                color: value === "Product" ? "#074EE8" : "#111111",
                textAlign: "center",
                fontFamily: "Unilever Shilling",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "20px",
                textTransform: "none", // Prevent text from being in uppercase
              }}
            />
            <Tab
              label="Campaign"
              value="Campaign"
              sx={{
                color: value === "Campaign" ? "#074EE8" : "#111111",
                textAlign: "center",
                fontFamily: "Unilever Shilling",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "20px",
                textTransform: "none", // Prevent text from being in uppercase
              }}
            />
            <Tab
              label="Inspiration"
              value="Inspiration"
              sx={{
                color: value === "Inspiration" ? "#074EE8" : "#111111",
                textAlign: "center",
                fontFamily: "Unilever Shilling",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "20px",
                textTransform: "none", // Prevent text from being in uppercase
              }}
            />
          </Tabs>
          <Box sx={{ mt: 2 }}>
            {renderContent()} {/* Render content based on selected tab */}
          </Box>
          <Box
            sx={{
              mt: 2,
              display: "flex",
              justifyContent: "flex-end",
              columnGap: "15px",
            }}
          >
            <StandardButton
              label="Save"
              buttonStyle={cancelButtonStyle}
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              disableElevation
              onClick={handleClose}
              isDisabled={true}
            />

            <div style={{ marginTop: "6px" }}>
              <label
                htmlFor="fileInput"
                className="custom-button"
                style={{
                  borderRadius: "4px",
                  background: "#074EE8",
                  color: "#FCFCFC",
                  textAlign: "center",
                  fontFamily: "Unilever Shilling",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "20px",
                  textTransform: "none",
                  cursor: "pointer",
                  padding: "5px",
                }}
              >
                Choose
                <input
                  id="fileInput"
                  type="file"
                  style={{ display: "none" }}
                  onChange={(e) => handleFileInputChange(e, value)}
                />
                {/* onChange={(e) => handleFileChange(e, dispatch)} /> */}
              </label>
            </div>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default UploadImageModal;
