import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import viewPreviousBrief from "../../assets/images/viewPreviousBrief.png";
import leave from "../../assets/images/leave.png";
import {
  setTastewiseSelectionValue,
  setTopDishTastewiseSelectedVal,
  setTrendingRecipeTastewiseOthersSelectedVal,
  setTrendingRecipeTastewiseSelectedVal,
  setTrendingRecipeTastewiseOptions,
  setTrendingRecipeTastewiseIndexScoreSelectedVal,
  setTrendingRecipeOthersSelectedVal,
  setTrendingRecipeSelectedVal,
  setProductOthersSelectedStatus,
  setProductOthersSelectedVal,
  setProductDescriptionOthersSelectedVal,
  setUserPromptSelectedVal,
  setBriefId,
  setBriefData,
  setUploadedImageFileName,
  setUploadedImageURL,
  removeUploadedImage,
  setEmptyInsipirationImages,
  setBrandSelectedVal,
  setIsViewPreviousBriefModalOpen,
  setEmptyProductImages,
} from "../../slicers/createBriefSlicer";
import {
  setAudienceTypeSelectedVal,
  setPlatformSelectedVal,
  setCampaignObjectiveSelectedVal,
  setMediaTypeSelectedVal,
} from '../../slicers/addMoreFlavourSlicer';
import { setActiveIndexofTab, setInputEditPrompt, setPromptTonality } from "../../slicers/editYourBriefSlicer";
import { fetchData, fetchPreviousBrief } from "../../utilities/apiUtils/apiUtils";

function ViewPreviousBriefModal() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClose = () => {
    dispatch(setIsViewPreviousBriefModalOpen(false));
  };

  const {
    inspirationImageFileName,
    marketOptions,
    marketSelectedVal,
    brandSelectedVal,
    tastewiseSelectionValue,
    topDishSelectedVal,
    productSelectedVal,
    productDescriptionSelectedVal,
    trendingRecipeSelectedVal,
    dataSourceUserSelectionName,
    isViewPreviousBriefModalOpen,
    topDishTastewiseSelectedVal,
    trendingRecipeOptionsTastewise,
    userEmail,
  } = useSelector((state) => state.createBrief);

  const handleViewPrevious = () => {
    fetchPreviousBrief(
      dispatch,
      tastewiseSelectionValue,
      marketOptions,
      navigate,
      userEmail
    );
    // closing the modal
    handleClose();
  };

  const handleCreateNew = async () => {
    // Dispatch actions to reset Redux states
    // create brief page - redux states
    dispatch(setTastewiseSelectionValue("Type It In"));
    dispatch(setTopDishTastewiseSelectedVal(""));
    dispatch(setTrendingRecipeTastewiseOthersSelectedVal(""));
    dispatch(setTrendingRecipeTastewiseSelectedVal(""));
    dispatch(setTrendingRecipeTastewiseOptions([]));
    dispatch(setTrendingRecipeTastewiseIndexScoreSelectedVal(0.0));
    dispatch(setTrendingRecipeOthersSelectedVal(""));
    dispatch(setTrendingRecipeSelectedVal("Others"));
    dispatch(setProductOthersSelectedStatus(false));
    dispatch(setProductOthersSelectedVal(""));
    dispatch(setProductDescriptionOthersSelectedVal(""));
    dispatch(setUserPromptSelectedVal(""));
    dispatch(setBriefId(""));
    dispatch(setBriefData({}));

    // create brief page - add more flavour - redux states
    dispatch(setAudienceTypeSelectedVal("Age less than 35 years"));
    dispatch(setPlatformSelectedVal(["Instagram"]));
    dispatch(setCampaignObjectiveSelectedVal("Drive Awareness"));
    dispatch(setMediaTypeSelectedVal(["Long form Video"]));

    // reset images
    dispatch(setUploadedImageFileName({ tab: "Brand", fileName: "" }));
    dispatch(setUploadedImageURL({ tab: "Brand", url: "" }));
    // dispatch(setUploadedImageFileName({ tab: "Product", fileName: "" }));
    // dispatch(setUploadedImageURL({ tab: "Product", url: "" }));
    dispatch(setUploadedImageFileName({ tab: "Campaign", fileName: "" }));
    dispatch(setUploadedImageURL({ tab: "Campaign", url: "" }));
    dispatch(setEmptyInsipirationImages());
    dispatch(setEmptyProductImages())

    // creating a new brief should set the active tab of edit brief to preview tab
    dispatch(setActiveIndexofTab(3));
    dispatch(setInputEditPrompt(""));
    dispatch(setPromptTonality(""))
    fetchData(dispatch, tastewiseSelectionValue,
      marketSelectedVal,
      brandSelectedVal,
      tastewiseSelectionValue === "Top Dish Trend Index"
        ? topDishTastewiseSelectedVal
        : topDishSelectedVal,
      productSelectedVal && productSelectedVal.map(product => product.product_name).join(', '),
      productDescriptionSelectedVal || "", 
      trendingRecipeSelectedVal || "",  
      dataSourceUserSelectionName || "", 
      trendingRecipeOptionsTastewise || "")
    // closing the modal
    handleClose();
  };

  return (
    <Dialog open={isViewPreviousBriefModalOpen}>
      <Box sx={{ overflow: "hidden" }}>
        <Grid container spacing={2} justifyContent="center">
          <Grid
            item
            xs={12}
            sx={{ width: "100%", height: "300px", position: "relative" }}
          >
            {/* viewPreviousBrief.png image */}
            <img
              src={viewPreviousBrief}
              alt="View Previous or Create New Brief"
              style={{
                position: "absolute",
                left: "56%",
                transform: "translateX(-50%)",
                width: "65%",
                height: "auto",
                zIndex: "2",
              }}
            />
            {/* Leave.png background */}
            <div
              style={{
                position: "absolute",
                width: "176%",
                height: "75%",
                backgroundImage: `url(${leave})`,
                backgroundPosition: "70% 7%", // Adjust background position to crop desired section
                backgroundSize: "cover",
                zIndex: "1",
                left: "-10%",
              }}
            ></div>
          </Grid>
          <Grid item xs={12} sx={{ mt: "-10%" }}>
            {/* Static Text */}
            <Typography
              variant="subtitle1"
              align="center"
              sx={{
                fontFamily: "Unilever Shilling",
                fontSize: "18px",
                fontWeight: 600,
                lineHeight: "26px",
                color: "#47C8FF",
              }}
            >
              Your last created brief is "{brandSelectedVal} Brief"!
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ mt: "-3%", mb: "3%" }}>
            {/* Static Text */}
            <Typography
              variant="subtitle1"
              align="center"
              sx={{
                fontFamily: "Unilever Shilling",
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "26px",
                color: "#111111",
              }}
            >
              Do you want to create new brief or view previous one?
            </Typography>
          </Grid>
          <Grid item xs={12} container justifyContent="center" sx={{ mb: 2 }}>
            {/* Buttons */}
            <Button
              variant="contained"
              color="primary"
              sx={{
                width: "128px",
                height: "28px",
                padding: "0px 14px",
                borderRadius: "20px 20px 20px 20px",
                background: "#005EEF",
                boxShadow: "0px 4px 4px 0px #0000001C",
                marginRight: "8px", // Adjust spacing between buttons
              }}
              onClick={handleViewPrevious}
            >
              <Typography
                variant="subtitle1"
                align="center"
                sx={{
                  fontFamily: "Unilever Shilling",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "20px",
                  color: "#FCFCFC",
                  textTransform: "none",
                }}
              >
                View Previous
              </Typography>
            </Button>
            <Typography variant="subtitle1" align="center">
              or
            </Typography>
            <Button
              variant="contained"
              color="primary"
              sx={{
                width: "128px",
                height: "28px",
                padding: "8px 16px",
                borderRadius: "20px 20px 20px 20px",
                background: "#005EEF",
                boxShadow: "0px 4px 4px 0px #0000001C",
                marginLeft: "8px", // Adjust spacing between buttons
              }}
              onClick={handleCreateNew}
            >
              <Typography
                variant="subtitle1"
                align="center"
                sx={{
                  fontFamily: "Unilever Shilling",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "20px",
                  color: "#FCFCFC",
                  textTransform: "none",
                }}
              >
                Create New
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={12} sx={{ height: "32px" }} />
        </Grid>
      </Box>
    </Dialog>
  );
}

export default ViewPreviousBriefModal;
