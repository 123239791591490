import React, { useState, useRef, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import UserInputs from "../UserInputs/UserInputs";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import InfoIcon from "@mui/icons-material/Info";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import ShapeYourBrief from "../ShapeYourBrief/ShapeYourBrief";
import AddMoreFlavour from "../AddMoreFlavour/AddMoreFlavour";
import UploadImageModal from "../UploadImageModal/UploadImageModal";
import CustomTooltip from "../CustomTooltip/CustomTooltip";
import StandardButton from "../StandardButton/StandardButton";
import {
  setMarketSelectedVal,
  setBrandSelectedVal,
  setTopDishTastewiseSelectedVal,
  setTrendingRecipeTastewiseOptions,
  setTrendingRecipeTastewiseSelectedVal,
  setTastewiseSelectionValue,
  setTrendingRecipeTastewiseIndexScoreSelectedVal,
  setIsLoading,
  setBriefId,
  setBriefData,
  setBlobProductImageURL,
} from "../../slicers/createBriefSlicer";
import {
  topDishTrendIndexTooltipData,
  typeItInTooltipData,
  topDishTooltipData,
  trendingRecipeTooltipData,
  productTooltipData,
  productDescriptionTooltipData,
  uploadImagesTooltipData,
  topDishDisabled,
} from "../../Constant/CreateBriefTooltip";
import {
  setAboutBrand,
  setAboutProduct,
  setCampaignGoal,
  setCampaignOverview,
  setCaptionIdeas,
  setContentMandatories,
  setCreateUpdateDateTime,
  setDeliverables,
  setHashtags,
  setHeaderQuestionsLabels,
  setLinkToUse,
  setPostingTimeLine,
  setSocialMediaHashtag,
  setSuggestedHeadlines,
} from "../../slicers/editYourBriefSlicer";
import apiService from "../../utilities/service/apiService";
import {
  apiCallFetchBrand,
  getImageUrl,
} from "../../utilities/apiUtils/apiUtils";
import { removeIndexScore } from "../../utilities/stringUtils";
import "./styles.css";
import { getProductImageKey } from "../../Constant/ProductImages";
import {
  cookYourBriefText,
  gatherIngrediantText,
  invalidFileErrorText,
  topDistTrendIndexText,
  trendIndexUploadText,
  typeItInText,
} from "../../Constant/Header";
import { useTextLabels } from "../../Constant/useTextLabels";

const useStyles = makeStyles((theme) => ({
  textField: {
    "& .MuiInputBase-input": {
      padding: "6px 8px", // Adjust the padding to decrease the height
    },
    "& .MuiInputLabel-root": {
      fontSize: "14px", // Adjust the font size of the label if needed
    },
  },
  ovalPaper: {
    width: "100%",
    height: "35px",
    borderRadius: "20px !important", // Adjust the border-radius to control the curvature of the edges
    overflow: "hidden", // Ensure content inside does not overflow the cylinder shape
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.08) !important", // Add shadow for depth effect
    backgroundColor: "rgba(255, 255, 255, 0.80) !important",
    border: "1px solid #F7F7F7",
  },
}));

function CustomCard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const labels = useTextLabels();
  // for upload image button
  const fileInputRef = useRef(null);

  const classes = useStyles();
  const {
    topDishSelectedVal,
    trendingRecipeTastewiseSelectedVal,
    productSelectedVal,
    productOptions,
    productOthersSelectedVal,
    productDescriptionOthersSelectedVal,
    topDishTastewiseSelectedVal,
    topDishOptions,
    trendingRecipeTastewiseOptions,
    trendingRecipeTastewiseOthersSelectedVal,
    trendingRecipeOptions,
    trendingRecipeSelectedVal,
    trendingRecipeOthersSelectedVal,
    tastewiseSelectionValue,
    marketOptions,
    marketSelectedVal,
    brandSelectedVal,
    trendingRecipeTastewiseIndexScoreSelectedVal,
    productDescriptionSelectedVal,
    userPromptSelectedVal,
    userEmail,
    isTastewiseFeatureRequired,
    productOthersSelectedStatus,
    blobProductImageURL,
    isBlobImageLoading
  } = useSelector((state) => state.createBrief);
  const {
    audienceTypeSelectedVal,
    platformSelectedVal,
    campaignObjectiveSelectedVal,
    mediaTypeSelectedVal,
    selectedOutputLanguage,
    selectedOutputLangaugeId,
  } = useSelector((state) => state.addMoreFlavour);

  useEffect(() => {
    const fetchLabels = async () => {
      const response = await apiService({
        method: "GET",
        url: `${window.location.origin}/labels/${selectedOutputLangaugeId}`,
        body: {
          headers: {
            "Content-Type": "application/json",
          },
        },
      });
      if (response && response.length > 0) {
        dispatch(setHeaderQuestionsLabels(response));
      }
    };

    fetchLabels();
  }, [selectedOutputLangaugeId]);




  const productInfo = [
    "Zero-Salt Veg Stock",
    "Hellmann's Real Mayonnaise Squeeze Bottle",
    "Knorr Cheddar Broccoli Pasta",
    "Others",
  ];

  const goButtonStyle = {
    height: "40px",
    width: "76px",
    fontSize: "22px",
    backgroundColor: "#3459c1",
    textTransform: "none",
    fontFamily: "Unilever Shilling",
  };

  // checking if the go button should be enabled or disabled
  const isButtonDisabled = () => {
    if (tastewiseSelectionValue === "Top Dish Trend Index") {
      return !(
        topDishTastewiseSelectedVal &&
        trendingRecipeTastewiseSelectedVal &&
        (productOthersSelectedStatus
          ? productDescriptionOthersSelectedVal
          : productSelectedVal) &&
        !isBlobImageLoading
      );
    } else {
      return !(
        topDishSelectedVal &&
        trendingRecipeOthersSelectedVal &&
        (productOthersSelectedStatus
          ? productDescriptionOthersSelectedVal
          : productSelectedVal) &&
        !isBlobImageLoading
      );
    }
  };

  const handleGoButtonClick = async () => {
    try {
      const response = await apiService({
        method: "POST",
        url: `${process.env.REACT_APP_AI_API_URL}/brief/generate-brief`,
        body: {
          market: marketSelectedVal,
          brand: brandSelectedVal,
          top_dish:
            tastewiseSelectionValue === "Top Dish Trend Index"
              ? topDishTastewiseSelectedVal
              : topDishSelectedVal,
          trending_recipe:
            tastewiseSelectionValue === "Top Dish Trend Index"
              ? trendingRecipeTastewiseSelectedVal === "Others"
                ? trendingRecipeTastewiseOthersSelectedVal
                : removeIndexScore(trendingRecipeTastewiseSelectedVal)
              : trendingRecipeSelectedVal === "Others"
              ? trendingRecipeOthersSelectedVal
              : trendingRecipeSelectedVal,
          index_score: trendingRecipeTastewiseIndexScoreSelectedVal,
          product: productSelectedVal,
          audience_type: audienceTypeSelectedVal,
          platform: platformSelectedVal,
          campaign_objective: campaignObjectiveSelectedVal,
          media_type: mediaTypeSelectedVal,
          default_prompt: userPromptSelectedVal,
          user_name: userEmail,
          output_language: selectedOutputLanguage,
        },
        headers: {
          "Content-Type": "application/json",
        },
      });
      
      const briefRequestBody = {}; // Initialize an empty object to hold the request body
      briefRequestBody["MARKET_NAME"] = marketSelectedVal;
      briefRequestBody["BRAND_NAME"] = brandSelectedVal;
      briefRequestBody["TOP_DISH_NAME"] =
        tastewiseSelectionValue === "Top Dish Trend Index"
          ? topDishTastewiseSelectedVal
          : topDishSelectedVal;
      briefRequestBody["TRENDING_RECIPE_NAME"] =
        tastewiseSelectionValue === "Top Dish Trend Index"
          ? trendingRecipeTastewiseSelectedVal === "Others"
            ? trendingRecipeTastewiseOthersSelectedVal
            : trendingRecipeTastewiseSelectedVal
          : trendingRecipeSelectedVal === "Others"
          ? trendingRecipeOthersSelectedVal
          : trendingRecipeSelectedVal;
      briefRequestBody["PRODUCT"] = productSelectedVal;
      briefRequestBody["AUDIENCE_TYPE"] = audienceTypeSelectedVal;
      briefRequestBody["PLATFORM"] = JSON.stringify(platformSelectedVal);
      briefRequestBody["CAMPAIGN_OBJECTIVE"] = campaignObjectiveSelectedVal;
      briefRequestBody["MEDIA_TYPE"] = JSON.stringify(mediaTypeSelectedVal);
      briefRequestBody["USER_PROMPT"] = userPromptSelectedVal;
      briefRequestBody["EDIT_PROMPT"] = "";
      briefRequestBody["DATASOURCE_USER_SELECTION_NAME"] =
        tastewiseSelectionValue;
      briefRequestBody["TRENDING_RECIPE_OPTIONS_TASTEWISE"] = JSON.stringify(
        trendingRecipeTastewiseOptions
      );
      briefRequestBody["VIDEO_LINK"] = "";
      briefRequestBody["LANG"] = selectedOutputLangaugeId;

      response.data.forEach((item) => {
        switch (item.question) {
          case "ABOUT THE BRAND":
            dispatch(setAboutBrand(item.response));
            briefRequestBody["ABOUT_THE_BRAND"] = item.response;
            break;
          case "ABOUT THE PRODUCT":
            dispatch(setAboutProduct(item.response));
            briefRequestBody["ABOUT_THE_PRODUCT"] = item.response;
            break;
          case "CAMPAIGN BACKGROUND":
            dispatch(setCampaignOverview(item.response));
            briefRequestBody["CAMPAIGN_BACKGROUND"] = item.response;
            break;
          case "THE ASK":
            dispatch(setCampaignGoal(item.response));
            briefRequestBody["THE_ASK"] = item.response;
            break;
          case "CAMPAIGN KEY MESSAGE":
            dispatch(
              setCaptionIdeas(
                item.response
                  .split("\n")
                  .map((line) => line.replace(/^\d+\. /, ""))
              )
            );
            // dispatch(setCaptionIdeas(item.response));
            briefRequestBody["CAMPAIGN_KEY_MESSAGE"] = item.response;
            break;
          case "SUGGESTED CAPTIONS":
            dispatch(
              setSuggestedHeadlines(
                item.response
                  .split("\n")
                  .map((line) => line.replace(/^\d+\. /, ""))
              )
            );
            briefRequestBody["SUGGESTED_CAPTIONS"] = item.response;
            break;
          case "CONTENT MANDATORIES + IMPORTANT DO'S & DON'T'S":
            const { dos, donts } = item.response;
            const contentMandatories = [
              {
                do: dos.map((item) => item.trim()),
                donts: donts.map((item) => item.trim()),
              },
            ];
            dispatch(setContentMandatories(contentMandatories));
            briefRequestBody["CONTENT_MANDATORIES"] = JSON.stringify(
              item.response
            );
            break;
          case "SOCIAL MEDIA HANDLES & HASHTAGS":
            const parsedResponse = JSON.parse(item.response.replace(/'/g, '"'));
            const socialMediaHandles = parsedResponse.social_media_handles;
            const hashtags = parsedResponse.hashtags;
            console.log(socialMediaHandles, "888");
            dispatch(setSocialMediaHashtag(socialMediaHandles));
            dispatch(setHashtags(hashtags));
            briefRequestBody["SOCIAL_MEDIA_HANDLES_HASHTAGS"] = item.response;
            break;
          case "LINKS TO USE WITHIN CAPTIONS":
            dispatch(setLinkToUse(item.response));
            briefRequestBody["LINKS_TO_USE_WITHIN_CAPTIONS"] = item.response;
            break;
          case "POSTING TIMELINE":
            const jsonString = item.response.replace(/'/g, '"'); // Replace single quotes with double quotes
            const timelineData = JSON.parse(jsonString); // Parse the JSON string into an object

            const timelineDataLocal = {
              [labels.CONTRACTUAL_BRIEFING]:
                timelineData["Contractual briefing"],
              [labels.SHOOTING_PRODUCTION]: timelineData["Shooting/Production"],
              [labels.SEND_CONTENT_TO_BRAND_TEAM_FOR_REVIEW]:
                timelineData["Send content for brand to review"],
              [labels.EDIT_2_2]: timelineData["Edit 2/2"],
              [labels.FINAL_APPROVAL]: timelineData["Final Approval"],
              [labels.CONTENT_GO_LIVE]: timelineData["Content go-live"],
            };

            dispatch(setPostingTimeLine(timelineDataLocal)); // Dispatch the parsed timelineData

            briefRequestBody.POSTING_TIMELINE_contractual_briefing = new Date(
              timelineData["Contractual briefing"]
            )
              .toISOString()
              .split("T")[0];
            briefRequestBody.POSTING_TIMELINE_shooting_production = new Date(
              timelineData["Shooting/Production"]
            )
              .toISOString()
              .split("T")[0];
            briefRequestBody.POSTING_TIMELINE_send_content_to_knorr_for_review =
              new Date(timelineData["Send content for brand to review"])
                .toISOString()
                .split("T")[0];
            briefRequestBody.POSTING_TIMELINE_edit_2_2 = new Date(
              timelineData["Edit 2/2"]
            )
              .toISOString()
              .split("T")[0];
            briefRequestBody.POSTING_TIMELINE_final_approval = new Date(
              timelineData["Final Approval"]
            )
              .toISOString()
              .split("T")[0];
            briefRequestBody.POSTING_TIMELINE_content_go_alive = new Date(
              timelineData["Content go-live"]
            )
              .toISOString()
              .split("T")[0];

            break;

          case "DELIVERABLES":
            dispatch(setDeliverables(item.response));
            briefRequestBody["DELIVERABLES"] = item.response;
            break;

          default:
            break;
        }
      });
      briefRequestBody["CREATION_TIME"] = response["created_dttm"];
      briefRequestBody["UPDATE_TIME"] = response["created_dttm"];

      dispatch(setCreateUpdateDateTime(response.created_dttm));
      //  createBrief API call using apiService
      const createBrief = await apiService({
        method: "POST",
        url: `${window.location.origin}/briefs/`,
        body: {
          ...briefRequestBody,
          Email: userEmail,
          HASHTAGS: "",
        },
        headers: {
          "Content-Type": "application/json",
        },
      });

      dispatch(setBriefData(createBrief));
      dispatch(setBriefId(createBrief.BRIEF_ID));

      navigate("/EditYourBrief");
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
    }
  };

  const [expanded, setExpanded] = useState("panel1"); // default to ShapeYourBrief being open
  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // image upload modal
  const [imageDialogOpen, setImageDialogOpen] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  // Snackbar close handler
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const uploadImagesButtonStyle = {
    height: "24px",
    width: "89px",
    fontSize: "14px",
    backgroundColor: "#47C8FF",
    fontFamily: "Unilever Shilling",
    textTransform: "none",
    borderRadius: "4px",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.08)",
  };

  const inputLabelStyle = {
    color: "#111111",
    fontFamily: "Unilever Shilling",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "20px",
  };

  const updateMarketName = (market) => {
    const marketMappings = {
      UK: "United Kingdom",
      USA: "United States of America",
    };

    return marketMappings[market] || market; // If the market is not found in mappings, return the original market name
  };

  const updateBrandName = (brand) => {
    const brandMappings = {
      "Hellmann's Mayonnaise": "Hellmann's",
    };

    return brandMappings[brand] || brand; // If the brand is not found in mappings, return the original brand name
  };

  const handleUpload = async () => {
    const file = fileInputRef.current.files[0];
    if (!file) {
      console.error("No file selected.");
      return;
    }

    // Validate file type
    if (file.type !== "application/pdf") {
      console.error("Invalid file type. Please select a PDF file.");
      setSnackbarOpen(true); // Open Snackbar when file type is not PDF
      return;
    }

    try {
      dispatch(setIsLoading(true));
      const formData = new FormData();
      formData.append("pdf_file", file);

      const response = await axios.post(
        `${window.location.origin}/upload-pdf/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("API response:", response.data);
      dispatch(setMarketSelectedVal(updateMarketName(response.data.market)));
      apiCallFetchBrand(
        updateMarketName(response.data.market),
        dispatch,
        marketOptions,
        updateBrandName(response.data.brand)
      );
      dispatch(setBrandSelectedVal(updateBrandName(response.data.brand)));

      // when user uploads tastewise pdf, top dish, the trending recipe dropdowns and selected values are stored in redux
      dispatch(setTopDishTastewiseSelectedVal(response.data.top_dish));
      dispatch(setTrendingRecipeTastewiseOptions(response.data.table_data));
      dispatch(
        setTrendingRecipeTastewiseSelectedVal(
          response.data.table_data[0].trending_recipe
        )
      );
      dispatch(
        setTrendingRecipeTastewiseIndexScoreSelectedVal(
          Number(response.data.table_data[0].index_score)
        )
      );
    } catch (error) {
      console.error("Error uploading PDF:", error);
      dispatch(setIsLoading(true));
    } finally {
      dispatch(setIsLoading(true));
    }
  };

  const productDropdownOptions = productOptions.map(product => ({
    PRODUCT_NAME: product.product_name,
    PRODUCT_DESCRIPTION: product.product_description
  }));
  const uploadPDFInfo = {
    tooltipData: "Upload your Tastewise Trend Index PDF here",
    tooltipPlacement: "bottom",
    tooltipWidth: "150px",
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sx={{ height: "30px" }}></Grid>

        <Grid item xs={4}></Grid>
        {/* Heading Section */}
        <Grid item xs={4} style={{ textAlign: "center" }}>
          <Typography
            variant="h5"
            component="h5"
            sx={{
              color: "#111111",
              fontFamily: "Unilever Shilling",
              fontSize: "24px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "26px",
            }}
          >
            {cookYourBriefText}
          </Typography>
          <Typography
            variant="h6"
            component="h6"
            sx={{
              color: "#111",
              fontFamily: "Unilever Shilling",
              fontSize: "18px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "26px",
            }}
          >
            {gatherIngrediantText}
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
          style={{
            display: "inline-flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: "10px",
          }}
        ></Grid>

        <Grid item xs={3} sm={3} md={3} lg={3} xl={4}></Grid>
        <Grid item xs={6} sm={6} md={6} lg={6} xl={4}>
          {/* Main Section */}
          <Grid container spacing={2}>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              {/* radio buttons */}
              <Paper
                elevation={3}
                className={classes.ovalPaper}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Grid
                  container
                  spacing={1}
                  justifyContent="center"
                  alignItems="center"
                >
                  {/* <Grid item xs={1}></Grid> */}
                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={tastewiseSelectionValue}
                      onChange={(event) =>
                        dispatch(setTastewiseSelectionValue(event.target.value))
                      }
                    >
                      <Grid container spacing={0.5}>
                        <Grid item xs={6} sx={{ textAlign: "right" }}>
                          <FormControlLabel
                            value="Top Dish Trend Index"
                            disabled={
                              !isTastewiseFeatureRequired ? true : false
                            }
                            control={<Radio />}
                            // label="Top Dish Trend Index"
                            className="radioLabel"
                            label={
                              <Typography
                                style={{
                                  color: !isTastewiseFeatureRequired
                                    ? "#8F8F8F"
                                    : "#111111",
                                  fontFamily: "Unilever Shilling",
                                  fontSize: "14px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  display: "flex",
                                  alignItems: "center", // Center align icon and text
                                }}
                              >
                                {topDistTrendIndexText}
                                <CustomTooltip
                                  title={
                                    !isTastewiseFeatureRequired
                                      ? topDishDisabled
                                      : topDishTrendIndexTooltipData
                                  }
                                  width={250}
                                  tooltipPlacement={"bottom"}
                                >
                                  <InfoIcon
                                    style={{
                                      fontSize: "16px",
                                      color: "#111111",
                                    }}
                                  />
                                </CustomTooltip>
                              </Typography>
                            }
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <FormControlLabel
                            value="Type It In"
                            control={<Radio />}
                            label={
                              <Typography
                                style={{
                                  color: "#111111",
                                  fontFamily: "Unilever Shilling",
                                  fontSize: "14px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  display: "flex",
                                  alignItems: "center", // Center align icon and text
                                }}
                              >
                                {typeItInText}
                                <CustomTooltip
                                  title={typeItInTooltipData}
                                  width={230}
                                  tooltipPlacement={"right-start"}
                                >
                                  <InfoIcon style={{ fontSize: "16px" }} />
                                </CustomTooltip>
                              </Typography>
                            }
                          />
                        </Grid>
                      </Grid>
                    </RadioGroup>
                  </Grid>
                  {/* <Grid item xs={1}></Grid> */}
                </Grid>
              </Paper>
            </Grid>

            {/* upload tastewise pdf section */}
            <Grid item xs={12} style={{ textAlign: "left" }}>
              {/* Snackbar for invalid file type */}
              <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000} // Adjust duration as needed
                onClose={handleCloseSnackbar}
              >
                <MuiAlert
                  elevation={6}
                  variant="filled"
                  onClose={handleCloseSnackbar}
                  severity="error"
                >
                  {invalidFileErrorText}
                </MuiAlert>
              </Snackbar>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleUpload}
                accept="application/pdf"
              />
              <CustomTooltip
                title={uploadPDFInfo.tooltipData}
                tooltipPlacement={uploadPDFInfo.tooltipPlacement}
                width={uploadPDFInfo.tooltipWidth}
              >
                <Button
                  onClick={() => fileInputRef.current.click()}
                  variant="contained"
                  startIcon={<CloudUploadIcon />}
                  style={{
                    height: "24px",
                    // width: "199px",
                    fontSize: "14px",
                    backgroundColor: "#47C8FF",
                    textTransform: "none",
                    fontFamily: "Unilever Shilling",
                    visibility:
                      tastewiseSelectionValue === "Top Dish Trend Index"
                        ? "visible"
                        : "hidden",
                  }}
                >
                  {trendIndexUploadText}
                </Button>
              </CustomTooltip>
            </Grid>

            <Grid item xs={12}>
              <Box>
                {tastewiseSelectionValue === "Top Dish Trend Index" ? (
                  <UserInputs
                    label="Top Dish"
                    dropdownOptions={productInfo}
                    fieldType="text"
                    showAsterisk={true}
                    labelStyle={inputLabelStyle}
                    showInfoIcon={true}
                    textFieldValue={topDishTastewiseSelectedVal}
                    disabledState={true}
                    tooltipData={topDishTooltipData}
                    tooltipPlacement={"right-start"}
                    tooltipWidth={150}
                    textBoxHeight={"32px"}
                    textBoxWidth={"281px"}
                  />
                ) : (
                  <UserInputs
                    label="Top Dish"
                    dropdownOptions={topDishOptions.map(
                      (top_dish) => top_dish.top_dish_name
                    )}
                    fieldType="dropdown"
                    className="select"
                    showAsterisk={true}
                    labelStyle={inputLabelStyle}
                    showInfoIcon={true}
                    dropdownWidth={281}
                    dropdownSelectedValue={topDishSelectedVal}
                    disableDropdown={
                      tastewiseSelectionValue === "Top Dish Trend Index"
                        ? true
                        : false
                    }
                    tooltipData={topDishTooltipData}
                    tooltipPlacement={"right-start"}
                    tooltipWidth={150}
                  />
                )}
              </Box>
            </Grid>

            <Grid item xs={12}>
              <UserInputs
                label="Trending Twist"
                dropdownOptions={
                  tastewiseSelectionValue === "Top Dish Trend Index"
                    ? trendingRecipeTastewiseOptions.map(
                        (recipe) => recipe.trending_recipe
                      )
                    : trendingRecipeOptions.map(
                        (recipe) => recipe.trending_recipe_name
                      )
                }
                fieldType="dropdown"
                className="select"
                showAsterisk={true}
                labelStyle={inputLabelStyle}
                showInfoIcon={true}
                textFieldValue={
                  tastewiseSelectionValue === "Top Dish Trend Index"
                    ? trendingRecipeTastewiseOthersSelectedVal
                    : trendingRecipeOthersSelectedVal
                }
                dropdownWidth={281}
                dropdownOthersWidth={110}
                dropdownSelectedValue={
                  tastewiseSelectionValue === "Top Dish Trend Index"
                    ? trendingRecipeTastewiseSelectedVal
                    : trendingRecipeSelectedVal
                }
                disableDropdown={false}
                tooltipData={trendingRecipeTooltipData}
                tooltipPlacement={"right-start"}
                tooltipWidth={150}
              />
            </Grid>

            <Grid item xs={12}>
              <Divider
                style={{
                  borderStyle: "dotted",
                  borderColor: "#C4C4C4",
                  width: "100%",
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <UserInputs
                label="Product"
                dropdownOptions={productDropdownOptions}
                fieldType="dropdown"
                className="select"
                showAsterisk={true}
                labelStyle={inputLabelStyle}
                showInfoIcon={true}
                textFieldValue={productOthersSelectedVal}
                dropdownWidth={281}
                dropdownOthersWidth={110}
                dropdownSelectedValue={productSelectedVal}
                disableDropdown={false}
                tooltipData={productTooltipData}
                tooltipPlacement={"right-start"}
                tooltipWidth={230}
                isMultiSKU={true}
              />
            </Grid>

            <Grid item xs={12}>
              {productOthersSelectedStatus && (
                <UserInputs
                  label="Product Description"
                  dropdownOptions={productInfo}
                  fieldType="text"
                  showAsterisk={true}
                  textFieldValue={productDescriptionOthersSelectedVal}
                  labelStyle={inputLabelStyle}
                  showInfoIcon={true}
                  tooltipData={productDescriptionTooltipData}
                  tooltipPlacement={"right-start"}
                  tooltipWidth={230}
                  textBoxWidth={"281px"}
                />
              )}
            </Grid>

            <Grid item xs={12}>
              <UserInputs
                label="Upload Images"
                fieldType="button"
                buttonLabel="Upload"
                fileUpload={false}
                buttonStyle={uploadImagesButtonStyle}
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
                showAsterisk={false}
                labelStyle={inputLabelStyle}
                showInfoIcon={true}
                onClick={() => setImageDialogOpen(true)}
                tooltipData={uploadImagesTooltipData}
                tooltipPlacement={"right-start"}
                tooltipWidth={260}
                buttonFormControlWidth={281}
              />
            </Grid>

            <Grid item xs={12}>
              <Divider
                style={{
                  borderStyle: "dotted",
                  borderColor: "#C4C4C4",
                  width: "100%",
                }}
              />
              <UploadImageModal
                open={imageDialogOpen}
                onClose={() => setImageDialogOpen(false)}
                tabNames={["Brand", "Product", "Campaign", "Inspiration"]}
                tabSelected={"Product"}
              />
            </Grid>

            {/* Shape Your Brief Section */}
            <Grid item xs={12}>
              <ShapeYourBrief
                expanded={expanded}
                onChange={handleAccordionChange}
              />
            </Grid>

            {/* Add More Flavour Section */}
            <Grid item xs={12}>
              <AddMoreFlavour
                expanded={expanded}
                onChange={handleAccordionChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "right",
              }}
            >
              {/* Center the button */}
              <StandardButton
                label="Go"
                buttonStyle={{
                  ...goButtonStyle,
                  cursor: isButtonDisabled() ? "not-allowed" : "pointer",
                  backgroundColor: isButtonDisabled() ? "#d5d5d5" : "#3459c1",
                }}
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                onClick={handleGoButtonClick}
                disabled={isButtonDisabled()}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={4}></Grid>
      </Grid>
    </Box>
  );
}

export default CustomCard;
